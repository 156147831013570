import { Theme } from './theme.model';
import { Slot } from './slot.model';
import { ProductFamily } from './product.model';


export enum DeliveryMethodOptions {
	dine_in = 'dine_in',
	take_away = 'take_away',
	home_delivery = 'home_delivery',
	seat_delivery = 'seat_delivery'
}

export interface DeliveryMethod {
	'@id': string;
	'@type': string;
	'id': number;
	'name': string;
	'slug': string;
	'isActive'?: boolean;
}

export interface Occupancy {
	'@id': string;
	'@type': string;
	'recordDate': string;
	'capacity': number;
	'consumed': number;
	'reserved': number;
}

export interface Place {
	'@id': string;
	'@type': string;
	'id': number;
	'name': string;
	'image': string;
	'themes': Theme[];
	'currentSlots': Slot[];
	'occupancy': Occupancy;
	'currentOccupancy': Occupancy[]; // date range
	'enabled'?: boolean;
	'currency'?: string;
	'productFamilies'?: ProductFamily[];
	'description': string;
	'isOpen': boolean; // computed
	'rating': number; // FIXME add to backend
	'places'?: Place[];
	'deliveryMethods': DeliveryMethod[];
}
