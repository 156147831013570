import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { ModalController, AlertController } from '@ionic/angular';

import { ApiService } from '@app/_services/api.service';
import { DataService } from '@app/_services/data.service';
import { StripeService } from '@app/_services/stripe.service';
import { ErrorService } from '@app/_services/error.service';

import { AddCardModalPage } from '@app/add-card-modal/add-card-modal.page';

import { Customer, CustomerCard } from '@app/_models/customer.model';
import { Payment } from '@app/_models/payment.model';

@Component({
	selector: 'app-payment-methods',
	templateUrl: './payment-methods.component.html',
	styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit, OnChanges {

	@Input() customer: Customer;
	@Input() stripeClientSecret: string;
	@Input() paymentError: string;
	@Input() paymentMethodId: string;
	// @Input() lastAttemptedPayment: Payment;

	@Output() paymentMethodChange = new EventEmitter<string>();
	@Output() paymentMethodIdChange = new EventEmitter<string>();
	@Output() paymentMethodValidation = new EventEmitter<boolean>();
	@Output() paymentAuthentication = new EventEmitter<string>();
	@Output() paymentIntent = new EventEmitter<any>();

	cards: CustomerCard[];
	currentCardId: string;
	paymentMethod: string;
	valid: boolean;
	processing: boolean;

	// paymentError: string;
	// paymentMethodId: string;

	constructor(
		private modalController: ModalController,
		private apiService: ApiService,
		private dataService: DataService,
		private stripeService: StripeService,
		private errorService: ErrorService,
	) {
		console.log('Hello PaymentMethodsComponent');
	}

	ngOnInit() {
		console.log('ngOnInit PaymentMethodsComponent');
		this.paymentMethod = 'credit_card'; // for now
		if (this.customer) {
			this.getCustomerCards();
		}
	}

	ngOnChanges() {
		console.log('ngOnChanges PaymentMethodsComponent');
		console.log('paymentMethod: ', this.paymentMethod);
		console.log('paymentError: ', this.paymentError);
		console.log('paymentMethodId: ', this.paymentMethodId);
		// if (this.lastAttemptedPayment) {
		// 	this.paymentError = this.lastAttemptedPayment.paymentError;
		// 	this.paymentMethodId = this.lastAttemptedPayment.paymentMethodId;
		// }
	}

	/* ====================================================================== */
	/*   Payment Methods and validation
	/* ====================================================================== */
	changePaymentMethod(event) {
		console.log('changePaymentMethod: paymentMethod: ', this.paymentMethod);
		this.validate();
	}

	validate() {
		if (this.paymentMethod === 'credit_card' && this.currentCardId) {
			this.valid = true;
		} else {
			this.valid = false;
		}
		this.paymentMethodId = this.currentCardId;

		this.paymentMethodChange.emit(this.paymentMethod);
		this.paymentMethodIdChange.emit(this.paymentMethodId);
		this.paymentMethodValidation.emit(this.valid);
	}


	/* ====================================================================== */
	/*   Manage Customer Cards
	/* ====================================================================== */
	getCustomerCards() {
		this.processing = true;
		this.cards = [];
		this.currentCardId = '';
		this.apiService.getCustomerCards(this.customer).subscribe(
			data => {
				console.log('paymentMethods: ', data);
				if (data && data['hydra:member']) {
					const response = data['hydra:member'];
					if (response.length > 1 && response[0] === 'list') {
						const paymentMethods = response[1];
						paymentMethods.forEach(
							paymentMethod => {
								const card = {
									id: paymentMethod.id,
									brand: paymentMethod.card.brand,
									country: paymentMethod.card.country,
									exp_month: paymentMethod.card.exp_month,
									exp_year: paymentMethod.card.exp_year,
									fingerprint: paymentMethod.card.fingerprint,
									funding: paymentMethod.card.funding,
									last4: paymentMethod.card.last4,
								} as CustomerCard;
								this.cards.push(card);
							}
						);
						this.currentCardId = this.cards.length > 0 ? this.cards[0].id : null;
						this.validate();
					}
				}
				this.processing = false;
			},
			error => {
				console.log('getCustomerCards error: ', error);
				this.processing = false;
			}
		);
	}

	selectCard(card) {
		this.currentCardId = card.id;
		this.validate();
	}

	async addCard() {
		const modal = await this.modalController.create({
			component: AddCardModalPage,
			cssClass: 'overlay-modal card-modal'
		});
		modal.onDidDismiss().then(
			response => {
				console.log('AddCardModalPage dismiss: ', response);
				if (response.data != null) {
					if (response.data.result === 'success') {
						console.log('setup successful');
						this.getCustomerCards();
					} else if (response.data.result === 'cancel') {
						console.log('setup canceled');
					} else {
						this.errorService.showError(response.data.error);
					}
				}
			}
		);
		await modal.present();
	}


	/**
	 * Authenticate a payment that failed due to authentication_required
	 * @see https://stripe.com/docs/payments/save-and-reuse#web-create-payment-intent-off-session
	 */
	authenticatePayment() {
		this.processing = true;
		this.stripeService.authenticatePayment(this.stripeClientSecret, this.paymentMethodId)
			.then((result) => {
				console.log('authenticatePayment result: ', result);
				if (result) {
					if (result.error) {
						// Show error to your customer
						console.log('authenticatePayment error: ', result.error);
						console.log('authenticatePayment error (1): ', result.error.message);
						this.paymentAuthentication.emit('error');
					} else {
						if (result.paymentIntent.status === 'succeeded') {
							// The payment is complete!
							console.log('payment succesful');
							this.paymentIntent.emit(result.paymentIntent);
							this.paymentAuthentication.emit('success');

							// this.dataService.checkPaymentStatus().subscribe(
							// check on server
							// if payment is related to one or more carts, place all orders
							// payment -> bill -> carts -> place orders
							// this.dataService.checkPaymentStatus(this.lastAttemptedPayment).subscribe(
							// 	confirmed => {
							// 		if (confirmed) {
							// 			this.processing = false;
							// 			this.paymentAuthentication.emit('success');
							// 			// this.close('success');
							// 		} else {
							// 			// FIXME error message?
							// 			this.processing = false;
							// 			this.paymentAuthentication.emit('error');
							// 			// this.close('error');
							// 		}
							// 	},
							// 	error => {
							// 		// error already shown by data service
							// 		this.processing = false;
							// 		this.paymentAuthentication.emit('error');
							// 		// this.close('error');
							// 	}
							// );

						} else {
							console.log('authenticatePayment wrong status: ', result.paymentIntent.status);
							this.paymentAuthentication.emit('error');
							// this.close('error');
						}
					}
				} else {
					console.log('authenticatePayment error (2): empty result'); // FIXME
					this.paymentAuthentication.emit('error');
					// this.close('error');
				}
			}
		)
		.catch(err => {
			console.log('authenticatePayment error (3): ', err); // FIXME
			this.paymentAuthentication.emit('error');
			// this.close('error');
		});
	}


	/* ====================================================================== */

}
