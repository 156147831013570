import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { User } from '../_models/user.model';
import { County } from '../_models/county.model';
import { Place } from '../_models/place.model';
import { Slot } from '../_models/slot.model';
import { GeolocationPosition } from '../_models/geolocation.model';
import { Customer, CustomerAddress } from '../_models/customer.model';
import { Checkout } from '../_models/checkout.model';
import { Cart } from '../_models/cart.model';
import { Product } from '../_models/product.model';
import { GroupReservation } from '../_models/reservation.model';

@Injectable({
	providedIn: 'root'
})
export class ApiService {

	constructor(
		public http: HttpClient
	) {
		console.log('Hello ApiService');
	}

	private getDefaultHeaders() {
		return  new HttpHeaders({
			'X-AUTH-TOKEN': environment.golkee.apiKey,
			Accept: 'application/ld+json',
			'Content-Type': 'application/ld+json'
		});
	}

	private get(url, params) {
		console.log('API: get: ', url, params);
		const headers = this.getDefaultHeaders();
		return this.http.get(url, { params, headers });
	}

	private put(url, obj) {
		console.log('API: put: ', url, obj);
		const headers = this.getDefaultHeaders();
		return this.http.put(url, obj, {headers}); // FIXME check
	}

    private post(url, obj) {
		console.log('API: post: ', url, obj);
		const headers = this.getDefaultHeaders();
		return this.http.post(url, obj, {headers});
	}

	public healthCheck() {
		const params = {};
		const url = environment.golkee.apiHost + '/pubapp/health_check';
		return this.get(url, params);
	}


	/* ====================================================================== */
	/*   S E R V E R   V A L I D A T I O N S
	/*   FIXME move to validator?
	/* ====================================================================== */
	validateEmail(email) {
		const params = { email };
		const headers = new HttpHeaders({
			'X-AUTH-TOKEN': environment.golkee.apiKey,
			Accept: 'application/ld+json',
			'Content-Type': 'application/ld+json'
		});
		const url = environment.golkee.apiHost + '/pubapp/auth/check-email';
		return this.http.get(url, { params, headers});
	}

	validateUsername(username) {
		const params = { username };
		const headers = new HttpHeaders({
			'X-AUTH-TOKEN': environment.golkee.apiKey,
			Accept: 'application/ld+json',
			'Content-Type': 'application/ld+json'
		});
		const url = environment.golkee.apiHost + '/pubapp/auth/check-username/'; // FIXME endpoint does not exist on server
		return this.http.get(url, { params, headers});
	}

	/* ====================================================================== */
	/*  C O N F I G U R A T I O N
	/* ====================================================================== */
	public getConfiguration() {
		const params = {
			scope: 'c'
		};
		const url = environment.golkee.apiHost + '/api/public_configurations';
		return this.get(url, params);
	}


	/* ====================================================================== */
	/*   R E G I S T R A T I O N
	/* ====================================================================== */
	register(user: User) {
		const url = environment.golkee.apiHost + '/api/register';
		return this.post(url, user);
	}

	resetPassword(params) {
		const url = environment.golkee.apiHost + '/api/reset_password_requests';
		return this.post(url, params);
	}

	resendVerificationEmail(params) {
		const url = environment.golkee.apiHost + '/api/verify_account_requests';
		return this.post(url, params);
	}

	/* ====================================================================== */
	/*   U S E R S   A N D   C U S T O M E R S
	/* ====================================================================== */

	public getCustomer(): Observable<any> {
		const params = {};
		const url = environment.golkee.apiHost + '/api/customer/profile';
		return this.get(url, params);
	}

	public updateUserToken(user: User, token: string) {
		const params = {...user};
		delete params.token;
		delete params.refresh_token;
		params.fcmToken = token;
		const iri = '/api/users/' + user.id;
		const url = environment.golkee.apiHost + iri;
		return this.put(url, params);
	}


	/* ====================================================================== */
	/*   A D D R E S S E S
	/* ====================================================================== */

	/**
	 * Transform the CustomerAddress customer from full object to customer IRIs
	 */
	private normalizeCustomerAddress(customerAddress: CustomerAddress): CustomerAddress {

		console.log('API: normalizeCustomerAddress: customerAddress: ', customerAddress);

		// make a copy of the order object
		// to avoid modifying it
		const normalizedAddress = {
			...customerAddress
		};

		// change customer object to customer IRI
		normalizedAddress.customer = normalizedAddress.customer && normalizedAddress.customer['@id'] ?
			normalizedAddress.customer['@id'] : normalizedAddress.customer;

		return normalizedAddress;

	}

	public addCustomerAddress(customerAddress: CustomerAddress): Observable<any> {
		const params = this.normalizeCustomerAddress(customerAddress);
		const url = environment.golkee.apiHost + '/api/customer_addresses';
		return this.post(url, params); // headers);
	}

	public useCustomerAddress(customerAddress: CustomerAddress): Observable<any> {
		console.log('useCustomerAddress: customerAddress: ', customerAddress);
		const iri = customerAddress['@id'];
		const params = this.normalizeCustomerAddress(customerAddress);
		const url = environment.golkee.apiHost + iri + '/set_current';
		return this.put(url, params);
	}

	/* ====================================================================== */
	/*   C O U N T I E S
	/* ====================================================================== */

	public getActiveCounties() {
		const params = {
			active: true
		};
		const url = environment.golkee.apiHost + '/api/counties';
		return this.get(url, params);
	}


	/* ====================================================================== */
	/*   T H E M E S   A N D   P L A C E S
	/* ====================================================================== */

	public getThemes() {
		const params = {};
		const url = environment.golkee.apiHost + '/api/themes';
		return this.get(url, params);
	}

	public getPlacesByLocation(location: GeolocationPosition) {
		const params = {lat: '' + location.coords.latitude, lng: '' + location.coords.longitude};
		const url = environment.golkee.apiHost + '/api/places/by_location';
		return this.get(url, params);
	}

	public getPlacesByCounty(county: County) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/counties/' + county.id + '/places';
		return this.get(url, params);
	}

	public getPlacesByTheme(themeId) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/themes/' + themeId + '/places';
		return this.get(url, params);
	}

	public getPlaceById(placeId, params = {}) {
		const url = environment.golkee.apiHost + '/api/places/' + placeId;
		return this.get(url, params);
	}

	public getEMenuById(placeId) {
		const params = {
			'groups[]': 'e_menu'
		};
		const url = environment.golkee.apiHost + '/api/places/' + placeId;
		return this.get(url, params);
	}

	public searchPlaces(scope, searchTerm) {
		const params = {scope, term: searchTerm};
		const url = environment.golkee.apiHost + '/api/places/search_in_places'; // FIXME not in backend
		return this.get(url, params); // headers);
	}

	/* ====================================================================== */
	/*   A C C E S S
	/* ====================================================================== */
	// backend will filter places by operator
	public getPlaceByOperator() {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places';
		return this.get(url, params);
	}

	public checkAccess(place: Place, customerUid) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places/' + place.id + '/check_access/' + customerUid;
		return this.get(url, params);
	}

	public checkIn(place: Place, customer: Customer) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places/' + place.id + '/check_in/' + customer.uid;
		return this.get(url, params);
	}

	public checkOut(place: Place, customer: Customer) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places/' + place.id + '/check_out/' + customer.uid;
		return this.get(url, params);
	}

	/* ====================================================================== */
	/*   S L O T S
	/* ====================================================================== */
	public reserveSlot(place: Place, slot: Slot, customer: Customer) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places/' + place.id + '/slots/' + slot.id + '/reserve/' + customer.id;
		return this.put(url, params);
	}

	public cancelSlot(place: Place, slot: Slot, customer: Customer) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/places/' + place.id + '/slots/' + slot.id + '/cancel/' + customer.id;
		return this.put(url, params);
	}

	// public getCurrentSlotsByPlace(placeId) {
	// 	const params = {};
	// 	const url = environment.golkee.apiHost + '/api/places/' + placeId + '/current_slots';
	// 	return this.get(url, params);
	// }

	/* ====================================================================== */
	/*   C A R T S
	/* ====================================================================== */

	/**
	 * Transform the Cart:CustomerOrders from full objects to products IRIs
	 */
	private normalizeCart(cart: any): any {

		console.log('API: normalizeOrder: cart: ', cart);

		// make a copy of the cart object
		// to avoid modifying it
		// don't forget to include (or clear) the second level 'customerOrders'
		// @see https://dev.to/samdbeckham/deep-copy-and-the-immutability-issue--cd6
		const normalizedCart = {
			...cart,
			customerOrders: [],
			// items: [...order.items] // if we wanted the copy the second level property
		};

		// also copy the second level Order objects
		// that need modifying
		// we need to change full orders to IRIs
		cart.customerOrders.forEach( order => {
			const newOrder = order['@id'] ? order['@id'] : order;
			normalizedCart.customerOrders.push(newOrder);
		});

		// same for cart.customer
		normalizedCart.customer = normalizedCart.customer && normalizedCart.customer['@id'] ?
		normalizedCart.customer['@id'] : normalizedCart.customer;

		return normalizedCart;

	}


	public createCart(cart: any): Observable<any>  {
		const params = cart;
		const url = environment.golkee.apiHost + '/api/multi_carts';
		return this.post(url, params); // headers);
	}

	public placeAllCartOrders(cart: Cart): Observable<any> {
		const params = this.normalizeCart(cart);
		const iri = params['@id'];
		const url = environment.golkee.apiHost + iri + '/place_orders';
		return this.put(url, params); // headers);
	}

	/* ====================================================================== */
	/*   O R D E R S
	/* ====================================================================== */

	/**
	 * Transform the Order:Items:Products from full objects to products IRIs
	 */
	private normalizeOrder(order: any): any {

		console.log('API: normalizeOrder: order: ', order);

		// make a copy of the order object
		// to avoid modifying it
		// don't forget to include (or clear) the second level 'items'
		// @see https://dev.to/samdbeckham/deep-copy-and-the-immutability-issue--cd6
		const normalizedOrder = {
			...order,
			items: [],
			// items: [...order.items] // if we wanted the copy the second level property
		};

		// also copy the third level OrderItem objects
		// that need modifying
		// we need to change full products to IRIs
		order.items.forEach( item => {
			const newItem = {
				product:  item.product['@id'] ? item.product['@id'] : item.product,
				quantity: item.quantity,
				productOptions: [],
				variation: item.variation
			};

			// same for item.productOptions
			item.productOptions.forEach( option => {
				const newOption = option['@id'] ? option['@id'] : option;
				newItem.productOptions.push(newOption);
			});

			// same for item.variation
			newItem.variation = newItem.variation && newItem.variation['@id'] ?
			newItem.variation['@id'] : newItem.variation;

			normalizedOrder.items.push(newItem);
		});

		// same for order.marketplace
		normalizedOrder.marketplace = normalizedOrder.marketplace && normalizedOrder.marketplace['@id'] ?
		normalizedOrder.marketplace['@id'] : normalizedOrder.marketplace;

		// same for order.place
		normalizedOrder.place = normalizedOrder.place && normalizedOrder.place['@id'] ?
		normalizedOrder.place['@id'] : normalizedOrder.place;

		// same for order.cart
		normalizedOrder.cart = normalizedOrder.cart && normalizedOrder.cart['@id'] ?
		normalizedOrder.cart['@id'] : normalizedOrder.cart;

		return normalizedOrder;

	}

	public createOrder(order: any): Observable<any>  {
		const params = this.normalizeOrder(order);
		// const headers = new HttpHeaders({
		// 	'Accept': 'application/ld+json',
		// 	'Content-Type': 'application/ld+json'
		// });
		const url = environment.golkee.apiHost + '/api/customer_orders';
		return this.post(url, params); // headers);
	}

	public updateOrder(order: any): Observable<any>  {
		const params = this.normalizeOrder(order);
		const iri = params['@id'];

		// console.log('updateOrder: order', order);
		// console.log('updateOrder: params', params);
		// console.log('updateOrder: comparison', order.items === params.items);

		// const headers = new HttpHeaders({
		// 	'Accept': 'application/ld+json',
		// 	'Content-Type': 'application/ld+json'
		// });
		const url = environment.golkee.apiHost + iri;
		return this.put(url, params); // headers);
	}

	public getCustomerRequestsByPlace(placeId: number): Observable<any> {
		const params = {
			place: placeId
		};
		const url = environment.golkee.apiHost + '/api/customer_requests';
		return this.get(url, params);
	}

	/* ====================================================================== */
	/*   P R O D U C T S
	/* ====================================================================== */
	public getProductDetails(product: Product): Observable<any> {
		const params = {
			'groups[]': 'product:details'
		};
		const iri = product['@id'];
		const url = environment.golkee.apiHost + iri;
		return this.get(url, params); // headers);
	}

	/* ====================================================================== */
	/*   S T R I P E   P A Y M E N T S
	/* ====================================================================== */
	public generateSetupIntent(customer: Customer) {
		const params = {};
		const id = customer.id;
		const url = environment.golkee.apiHost + '/api/customers/' + id + '/stripe/create_setup_intent';
		return this.get(url, params);
	}

	public getCustomerCards(customer: Customer) {
		const params = {};
		const id = customer.id;
		const url = environment.golkee.apiHost + '/api/customers/' + id + '/stripe/cards';
		return this.get(url, params);
	}

	/* ====================================================================== */
	/*   Q R C O D E S
	/* ====================================================================== */
	public getQRCode(url) {
		const params = {
			f: 'json'
		};
		// FIXME validate url
		return this.get(url, params);
	}

	/* ====================================================================== */
	/*   Q R C O D E S
	/* ====================================================================== */
	public processPromocode(code) {
		const params = {
			code
		};
		const url = environment.golkee.apiHost + '/api/process_promo_codes';
		return this.post(url, params);
	}

	/* ====================================================================== */
	/*   N O T I F I C A T I O N S
	/* ====================================================================== */
	public getUserNotifications(userid) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/users/' + userid + '/notifications';
		return this.get(url, params);
	}

	public markNotificationAsRead(notification) {
		notification.readAt = new Date();
		const params = {
			readAt: notification.readAt
		};
		const id = notification.id;
		const url = environment.golkee.apiHost + '/api/notification_recipients/' + id;
		return this.put(url, params);
	}

	/* ====================================================================== */
	/*   R E S E R V A T I O N S
	/* ====================================================================== */
	/**
	 * Transform the Reservation customer from full object to customer IRIs
	 */
	private normalizeReservation(reservation: GroupReservation): any {

		console.log('API: normalizeReservation: reservation: ', reservation);

		// make a copy of the order object
		// to avoid modifying it
		const normalizedReservation = {
			...reservation
		};

		// change place object to place IRI
		// tslint:disable-next-line
		normalizedReservation.place = normalizedReservation.place.hasOwnProperty('@id') ? normalizedReservation.place['@id'] : normalizedReservation.place;

		// normalizedReservation.asOwnProperty('@id') ?
		// 	normalizedReservation.place['@id'] : normalizedReservation.place;

		// normalizedReservation.place = normalizedReservation.place && normalizedReservation.place['@id'] ?
		// 	normalizedReservation.place['@id'] : normalizedReservation.place;

		// change customer object to customer IRI
		normalizedReservation.customer = normalizedReservation.customer && normalizedReservation.customer['@id'] ?
			normalizedReservation.customer['@id'] : normalizedReservation.customer;

		return normalizedReservation;

	}

	public makeReservation(reservation: GroupReservation): Observable<any> {
		const params = this.normalizeReservation(reservation);
		const url = environment.golkee.apiHost + '/api/group_reservations';
		return this.post(url, params);
	}

	public cancelReservation(reservation: GroupReservation): Observable<any> {
		const params = this.normalizeReservation(reservation);
		const iri = reservation['@id'];
		const url = environment.golkee.apiHost + iri + '/cancel'; // FIXME missing on backend
		return this.put(url, params);
	}

	public getCustomerReservationsByPlace(placeId: number): Observable<any> {
		const params = {
			place: placeId
		};
		const url = environment.golkee.apiHost + '/api/customer_reservations';
		return this.get(url, params);
	}

	// public getUserReservations(customer: Customer) {
	// 	const params = {};
	// 	const id = customer.id;
	// 	const url = environment.golkee.apiHost + '/api/customers/' + id + '/reservations';
	// 	return this.get(url, params);
	// }

	/* ====================================================================== */
	/*   B I L L S / C H E C K O U T
	/* ====================================================================== */

	public getCheckoutById(checkoutId) {
		const params = {};
		const url = environment.golkee.apiHost + '/api/customer_checkouts/' + checkoutId;
		return this.get(url, params);
	}

	public generatePaymentIntent(checkout: Checkout, paymentMethod, paymentMethodId) {
		const params = {
			action: 'paymentIntent',
			payload: {
				paymentMethod,
				paymentMethodId
			}
		};
		const iri = checkout['@id'];
		const url = environment.golkee.apiHost + iri;
		return this.put(url, params);
	}

	public checkBillPaymentStatus(checkout: Checkout, paymentId): Observable<any> {
		const params = {
			action: 'checkPayment',
			payload: {
				paymentId
			}
		};
		const iri = checkout['@id'];
		const url = environment.golkee.apiHost + iri;
		return this.put(url, params);
	}

	public checkCartPaymentStatus(cart: Cart, paymentToken): Observable<any> {
		console.log('checkCartPaymentStatus: paymentToken: ', paymentToken);
		const params = this.normalizeCart(cart);
		params.paymentToken = paymentToken;
		console.log('checkCartPaymentStatus: params: ', params);
		const iri = params['@id'];
		const url = environment.golkee.apiHost + iri + '/check_payment';
		return this.put(url, params); // headers);
	}

}
