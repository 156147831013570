import { FormControl, FormGroup } from '@angular/forms';

export class PasswordValidator {

	// If our validation fails, we return an object with a key for the error name and a value of true.
	// Otherwise, if the validation passes, we simply return null because there is no error.

	static areNotEqual(formGroup: FormGroup) {
		let val;
		let valid = true;

		for (const key in formGroup.controls) {
			if (formGroup.controls.hasOwnProperty(key)) {
				const control: FormControl = formGroup.controls[key] as FormControl;

				if (val === undefined) {
					val = control.value;
				} else {
					if (val !== control.value) {
						valid = false;
						break;
					}
				}
			}
		}

		if (valid) {
			return null;
		}

		return {
			areNotEqual: true
		};
	}

	/**
	 * 	The PasswordStrength constraint computes a password strength based on the following rules:
	 *
	 * 	Does the password contain an alpha character?
	 * 	Does the password contain both lowercase and uppercase alpha characters?
	 * 	Does the password contain a digit?
	 * 	Does the password contain a special character (non-alpha/digit)?
	 * 	Does the password have a length of at least 13 characters.
	 * 	When a rules matches with the supplied password, 1 point is added to
	 *  the total password strength. The minimum strength is 1 and the maximum strength is 5.
	 *
	 * 	The textual representation of the strength levels are as follows:
	 *
	 * 	1: Very Weak (matches one rule)
	 * 	2: Weak
	 * 	3: Medium
	 * 	4: Strong (recommended for most usages)
	 * 	5: Very Strong (matches all rules, recommended for admin or finance related services)
	 *
	 *  @see: https://github.com/rollerworks/PasswordStrengthValidator/blob/master/docs/strength-validation.md
	 */
	static passwordStrength(control: FormControl) {

		const minStregth = 3; // medium

		let valid = true;

		const val = control.value;

		let passwordStrength = 0;

		if (val.match(/[a-zA-Z]/)) {
			passwordStrength++;

			if (!val.match(/[a-z]/)) {
				// $tips[] = 'lowercase_letters';
			} else if (val.match(/[A-Z]/)) {
				passwordStrength++;
			} else {
				// $tips[] = 'uppercase_letters';
			}
		} else {
			// $tips[] = 'letters';
		}

		if (val.match(/\d+/)) {
			passwordStrength++;
		} else {
			// $tips[] = 'numbers';
		}

		if (val.match(/[^a-zA-Z0-9]/)) {
			passwordStrength++;
		} else {
			// $tips[] = 'special_chars';
		}

		if (passwordStrength < minStregth) {
			valid = false;
		}

		if (valid) {
			return null;
		}

		return {
			passwordStrength: true
		};
	}

}
