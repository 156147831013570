import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// not in ionic themes app
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// check everything below

// forms
import { ReactiveFormsModule } from '@angular/forms';

// locale
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

// interceptors and translations
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// http interceptors
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { RefreshTokenInterceptor } from './_helpers/refresh-token.interceptor';
// import { ErrorInterceptor } from './_helpers/error.interceptor';

// pages, services, components
// import { ComponentsModule } from './components/components.module';

// modals
import { LocationModalPageModule } from './location-modal/location-modal.module';
import { SlotModalPageModule } from './slot-modal/slot-modal.module';
import { ProductModalPageModule } from './product-modal/product-modal.module';
import { CartModalPageModule } from './cart-modal/cart-modal.module';
import { NotificationsModalPageModule } from './notifications-modal/notifications-modal.module';
import { ReservationModalPageModule } from './reservation-modal/reservation-modal.module';
import { NewReservationModalPageModule } from './new-reservation-modal/new-reservation-modal.module';


// services
import { ErrorService } from './_services/error.service';
import { DataService } from './_services/data.service';


// external libraries
import { AutoCompleteModule } from 'ionic4-auto-complete';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';

import { WebIntent } from '@ionic-native/web-intent/ngx';

// register default locale
registerLocaleData(localePt);

// translation loader
export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		IonicModule.forRoot({
			mode: 'md' // disable ios mode
		}),
		AppRoutingModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
		// check everything below
		ReactiveFormsModule,
		HttpClientModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: (createTranslateLoader),
				deps: [HttpClient]
			}
		}),
		// components
		// ComponentsModule,
		// modals
		LocationModalPageModule,
		SlotModalPageModule,
		ReservationModalPageModule,
		NewReservationModalPageModule,
		ProductModalPageModule,
		CartModalPageModule,
		NotificationsModalPageModule,
		// external libraries
		AutoCompleteModule
	],
	providers: [
		StatusBar,
		SplashScreen,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		// check everything below
		// http interceptors
		{ provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
		// { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		/**
		 * Locale may come from service
		 * @see https://stackoverflow.com/a/39344889/2393822
		 */
		{ provide: LOCALE_ID, useValue: 'pt-PT' },
		ErrorService,
		DataService,
		// external libraries
		BarcodeScanner,
		WebIntent
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
