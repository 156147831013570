import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../_services/authentication.service';

@Injectable({
	providedIn: 'root'
})
export class JwtInterceptor implements HttpInterceptor {
	constructor(private authenticationService: AuthenticationService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		// console.log('JwtInterceptor: request: ', request);
		// if (request.url.indexOf('pubapp') !== -1) {
		// 	console.log('pubapp request, skipping JWT');
		// 	return next.handle(request);
		// }
		// add authorization header with jwt token if available
		// const currentUser = this.authenticationService.currentUserValue;
		// // console.log('JwtInterceptor: currentUser: ', currentUser);
		// if (currentUser && currentUser.token) {
		// 	request = request.clone({
		// 		setHeaders: {
		// 			Authorization: `Bearer ${currentUser.token}`,
		// 			Accept: 'application/ld+json',
		// 			'Content-Type': 'application/ld+json'
		// 		}
		// 	});
		// }
		//
		// return next.handle(request);

		// add authorization header with jwt token if available
		return next.handle(this.addAuthenticationToken(request));

	}

	// NOTE: also on refresh-token.interceptor
	addAuthenticationToken(request) {

		// console.log('@@@TOKENS [jwt]: addAuthenticationToken');

		// Get access token from Local Storage
		const accessToken = this.authenticationService.getAccessToken();

		// console.log('@@@TOKENS [jwt]: addAuthenticationToken: accessToken: ', accessToken);

		// If access token is null this means that user is not logged in
		// And we return the original request
		if (!accessToken) {
			return request;
		}

		// We clone the request, because the original request is immutable
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`
			}
		});

	}

}
