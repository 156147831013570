import { Component, OnInit, OnDestroy, Input, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
import { ApiService } from '@app/_services/api.service';
import { DataService } from '@app/_services/data.service';
import { StripeService } from '@app/_services/stripe.service';
import { ErrorService } from '@app/_services/error.service';

import { Customer, CustomerCard } from '@app/_models/customer.model';
import { Product } from '@app/_models/product.model';
import { OrderItem } from '@app/_models/order.model';
import { Bill } from '@app/_models/bill.model';
import { Checkout } from '@app/_models/checkout.model';
import { Payment } from '@app/_models/payment.model';
import { Observable, combineLatest } from 'rxjs';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { AddCardModalPage } from '@app/add-card-modal/add-card-modal.page';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.page.html',
  styleUrls: ['./payment-modal.page.scss'],
})
export class PaymentModalPage implements OnInit, OnDestroy {

	// Data passed in by componentProps
	@Input() bill: Bill;

	payment: Payment;
	customer$: Observable<Customer>;
	customer: Customer;
	cards: CustomerCard[];
	currentCardId: string;
	processing = false;
	valid = false;

	paymentMessages = {};
	sub: any;

	paymentMethod: string;

	constructor(
		private router: Router,
		private modalController: ModalController,
		private alertController: AlertController,
		private apiService: ApiService,
		private dataService: DataService,
		private stripeService: StripeService,
		private errorService: ErrorService,
		private translate: TranslateService
	) {
		console.log('Hello PaymentModalPage');
		this.translate.get('payment').subscribe( messages => {
			this.paymentMessages = messages;
		});
		this.paymentMethod = 'credit_card'; // for now
	}

	ngOnInit() {
		console.log('ngOnInit PaymentModalPage');
		console.log('bill: ', this.bill);

		this.customer$ = this.dataService.customer$;

		if (this.bill) {

			this.payment = this.bill.payments && this.bill.payments.length > 0 ? this.bill.payments[0] : null; // FIXME multiple payments

			this.sub = this.customer$.subscribe((customer) => {
				if (customer) {
					this.customer = customer;
					// this.getCustomerCards();
				}
			});
		}
	}

	ngOnDestroy() {
		console.log('ngOnDestroy PaymentModalPage');
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	/* ====================================================================== */
	/*   Payment Methods and validation component
	/* ====================================================================== */
	onPaymentMethodChange(event) {
		console.log('Payment Methods Component: paymentMethod: ', event);
		this.paymentMethod = event;
	}

	onPaymentMethodIdChange(event) {
		console.log('Payment Methods Component: currentCardId: ', event);
		this.currentCardId = event;
	}

	onPaymentMethodValidation(event) {
		console.log('Payment Methods Component: valid: ', event);
		this.valid = event;
	}

	onPaymentAuthentication(event) {
		console.log('Payment Methods Component: onPaymentAuthentication: ', event);
		if ('success' === event) {
			this.checkPaymentStatusAfterAuthentication();
		} else {
			this.errorService.showError('card_authentication_error');
		}
	}

	/* ====================================================================== */
	/*   Make Payment
	/* ====================================================================== */
	confirm() {

		if (!this.valid) {
			return;
		}

		this.processing = true;
		this.dataService.makePayment(this.bill, this.paymentMethod, this.currentCardId).subscribe(
			res => {

				// res is a Checkout Object
				// Checkout has a corresponding Bill
				// Bill status determines success or failure
				if (res) {

					this.processing = false;

					const checkout = res as Checkout;
					const bill = checkout.bill;

					// bill should be closed if payment is succesful
					if ('closed' === bill.status) {
						this.showSuccessMessage();
						this.close('success');
					} else {

						if (bill.payments.length > 0) {

							// setting the payment will update the payment methods component
							// with a button to authenticate or chose another card
							this.payment = bill.payments[bill.payments.length - 1];


							this.bill = bill;
							// this.errorService.showError(this.payment.status); // FIXME
						} else {
							this.errorService.showError('unknown_error');
						}

					}

				} else {
					this.errorService.showError('unknown_error');
					this.processing = false;
					// this.close('error');
				}
			},
			error => {
				this.errorService.showError(error);
				this.processing = false;
				// this.close('error');
			}
		);

	}

	checkPaymentStatusAfterAuthentication() {
		this.dataService.checkBillPaymentStatus(this.bill, this.payment).subscribe(
			confirmed => {
				this.processing = false;

				if (confirmed) {
					this.showSuccessMessage();
					this.close('success');
				} else {
					this.errorService.showError('unknown_error');
					this.processing = false;
				}
			},
			error => {
				this.errorService.showError(error);
				this.processing = false;
				// this.close('error');
			}
		);
	}

	//
	// /* ====================================================================== */
	// /*   Payment Methods and validation
	// /* ====================================================================== */
	// changePaymentMethod(event) {
	// 	console.log('changePaymentMethod: paymentMethod: ', this.paymentMethod);
	// 	this.validate();
	// }
	//
	// validate() {
	// 	this.valid = this.paymentMethod === 'credit_card' && this.currentCardId;
	// }
	//
	//
	// /* ====================================================================== */
	// /*   Manage Customer Cards
	// /* ====================================================================== */
	// getCustomerCards() {
	// 	this.cards = [];
	// 	this.currentCardId = '';
	// 	this.apiService.getCustomerCards(this.customer).subscribe(
	// 		data => {
	// 			console.log('paymentMethods: ', data);
	// 			if (data && data['hydra:member']) {
	// 				const response = data['hydra:member'];
	// 				if (response.length > 1 && response[0] === 'list') {
	// 					const paymentMethods = response[1];
	// 					paymentMethods.forEach(
	// 						paymentMethod => {
	// 							const card = {
	// 								id: paymentMethod.id,
	// 								brand: paymentMethod.card.brand,
	// 								country: paymentMethod.card.country,
	// 								exp_month: paymentMethod.card.exp_month,
	// 								exp_year: paymentMethod.card.exp_year,
	// 								fingerprint: paymentMethod.card.fingerprint,
	// 								funding: paymentMethod.card.funding,
	// 								last4: paymentMethod.card.last4,
	// 							} as CustomerCard;
	// 							this.cards.push(card);
	// 						}
	// 					);
	// 					this.currentCardId = this.cards.length > 0 ? this.cards[0].id : null;
	// 					this.validate();
	// 				}
	// 			}
	// 		}
	// 	);
	// }
	//
	// async addCard() {
	// 	const modal = await this.modalController.create({
	// 		component: AddCardModalPage,
	// 		cssClass: 'overlay-modal card-modal'
	// 	});
	// 	modal.onDidDismiss().then(
	// 		response => {
	// 			console.log('AddCardModalPage dismiss: ', response);
	// 			if (response.data != null) {
	// 				if (response.data.result === 'success') {
	// 					console.log('setup successful');
	// 					this.getCustomerCards();
	// 				} else if (response.data.result === 'cancel') {
	// 					console.log('setup canceled');
	// 				} else {
	// 					this.errorService.showError(response.data.error);
	// 				}
	// 			}
	// 		}
	// 	);
	// 	await modal.present();
	// }
	//
	//
	// /**
	//  * Authenticate a payment that failed due to authentication_required
	//  * @see https://stripe.com/docs/payments/save-and-reuse#web-create-payment-intent-off-session
	//  */
	// authenticatePayment() {
	// 	this.processing = true;
	// 	this.stripeService.authenticatePayment(this.bill)
	// 		.then((result) => {
	// 			console.log('authenticatePayment result: ', result);
	// 			if (result) {
	// 				if (result.error) {
	// 					// Show error to your customer
	// 					console.log('authenticatePayment error: ', result.error);
	// 					console.log('authenticatePayment error (1): ', result.error.message);
	// 				} else {
	// 					if (result.paymentIntent.status === 'succeeded') {
	// 						// The payment is complete!
	// 						console.log('payment succesful');
	//
	// 						this.dataService.checkPaymentStatus().subscribe(
	// 							confirmed => {
	// 								if (confirmed) {
	// 									this.showSuccessMessage();
	// 									this.processing = false;
	// 									this.close('success');
	// 								} else {
	// 									// FIXME error message?
	// 									this.processing = false;
	// 									this.close('error');
	// 								}
	// 							},
	// 							error => {
	// 								// error already shown by data service
	// 								this.processing = false;
	// 								this.close('error');
	// 							}
	// 						);
	//
	// 					} else {
	// 						console.log('authenticatePayment wrong status: ', result.paymentIntent.status);
	// 						this.close('error');
	// 					}
	// 				}
	// 			} else {
	// 				console.log('authenticatePayment error (2): empty result'); // FIXME
	// 				this.close('error');
	// 			}
	// 		}
	// 	)
	// 	.catch(err => {
	// 		console.log('authenticatePayment error (3): ', err); // FIXME
	// 		this.close('error');
	// 	});
	// }


	/* ====================================================================== */

	async showSuccessMessage() {
		// FIXME TODO
		// Perfom PayPal or Stripe payment process
		const alert = await this.alertController.create({
			// tslint:disable-next-line:no-string-literal
			header: this.paymentMessages['text']['success']['title'],
			// tslint:disable-next-line:no-string-literal
			message: this.paymentMessages['text']['success']['body'],
			buttons: ['OK']
		});
		alert.present().then(() => {});
	}

	/* ====================================================================== */

	close(result) {
		this.modalController.dismiss(result, null, 'PaymentModal');
	}

}
