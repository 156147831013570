import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
	enableProdMode();
	// FIXME uncomment
	// if (window) {
	// 	window.console.log = window.console.warn = window.console.info = window.console.debug = (() => {
	// 		// Don't log anything.
	// 	});
	// }
}

platformBrowserDynamic().bootstrapModule(AppModule)
.catch(err => console.log(err));
