import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, ModalController, AlertController } from '@ionic/angular';
import { Plugins } from '@capacitor/core';
import 'mycascais-plugin';

const { MyCascais } = Plugins;
// const { App } = Plugins;

@Component({
	selector: 'app-mycascais-login',
	templateUrl: './mycascais-login.page.html',
	styleUrls: ['./mycascais-login.page.scss'],
})
export class MycascaisLoginPage implements OnInit {

	// Data passed in by componentProps or route snapshot
	@Input() isModal: boolean;

	// myCascaisId = 'nsop.neds.mycascais';
	// myCascaisInstalled: boolean;

	marketAppLaunched = false;
	myCascaisAppLaunched = false;
	error = false;

	constructor(
		private router: Router,
		private modalController: ModalController
	) {
		console.log('Hello MycascaisLoginPage');
		console.log('Plugin MyCascais: ', MyCascais);
	}

	async ngOnInit() {
		console.log('ngOnInit MycascaisLoginPage');
		// await this.testPlugin();
	}

	async ionViewWillEnter() {
		console.log('ionViewWillEnter MycascaisLoginPage');

		// tries to open the App MyCascais 360
		// if the app is not installed, redirects to the App Store

		const res = await MyCascais.openMyCascais();
		console.log('openMyCascais: ' + JSON.stringify(res));

		if (res && res.result) {

			if ('MARKETAPP' === res.result) {

				this.marketAppLaunched = true;

			} else if ('SUCCESS' === res.result) {

				this.marketAppLaunched = true;

			} else {
				this.error = true;
			}

		} else {
			this.error = true;
		}




		// const isMyCascaisInstalledRes = await (MyCascais.isMyCascaisInstalled());
		// if ('SUCCESS' === isMyCascaisInstalledRes) {
		// 	this.myCascaisInstalled = true;
		// 	const openMyCascaisRes = await MyCascais.openMyCascais();
		// 	console.log('openMyCascais: ' + JSON.stringify(openMyCascaisRes));
		// }
	}

	// async launchPlayStore() {
	// 	const canOpen = await App.canOpenUrl({ url: this.myCascaisId });
	// 	if (canOpen) {
	// 		const res = await App.openUrl({ url: this.myCascaisId });
	// 		console.log('launchPlayStore response: ', res);
	// 	} else {
	// 		console.log('launchPlayStore: ERROR: Can\'t open play store');
	// 	}
	// }

	// async testPlugin() {
	// 	await (MyCascais.echo({
	// 		value: 'Hello from web!'
	// 	}));
	// 	const myCascaisInstalled = await (MyCascais.isMyCascaisInstalled());
	// 	console.log('myCascaisInstalled: ' + JSON.stringify(myCascaisInstalled));
	//
	// 	const result = await MyCascais.openMyCascais();
	// 	console.log('openMyCascais: ' + JSON.stringify(result));
	//
	// }

	dismiss() {
		if (this.isModal) {
			this.modalController.dismiss();
		} else {
			this.router.navigate(['/auth/login']);
		}
	}

}
