import { Component, OnInit, OnDestroy, ElementRef, ViewChild  } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { ApiService } from '@app/_services/api.service';
import { DataService } from '@app/_services/data.service';
import { StripeService } from '@app/_services/stripe.service';
import { Customer } from '@app/_models/customer.model';
import { ChangeDetectorRef } from '@angular/core';

declare var Stripe;

@Component({
	selector: 'app-add-card-modal',
	templateUrl: './add-card-modal.page.html',
	styleUrls: ['./add-card-modal.page.scss'],
})
export class AddCardModalPage implements OnInit, OnDestroy {

	@ViewChild('cardElement', {static: true}) cardElement: ElementRef;
	@ViewChild('cardErrors', {static: true}) cardErrors: ElementRef;

	processing = false;
	ready = false;
	valid = false;
	// stripe: any;
	card: any;
	clientSecret: string;
	customer: Customer;
	sub: any;

	result = {
		result: null,
		error: null
	};

	constructor(
		private modalController: ModalController,
		private ref: ChangeDetectorRef,
		private apiService: ApiService,
		private dataService: DataService,
		private stripeService: StripeService
	) {
		console.log('Hello AddCardModalPage');
	}


	ngOnInit() {
		console.log('ngOnInit AddCardModalPage');
		this.setupStripe();

		this.ready = false;
		this.valid = false;
		this.processing = true;

		this.sub = this.dataService.customer$.subscribe(
			customer => {
				if (customer) {

					console.log('AddCardModalPage: customer: ', customer);

					this.customer = customer;

					this.apiService.generateSetupIntent(customer).subscribe(

						clientSecret => {
							console.log('Generate Setup Intent Result: ', clientSecret);
							if (clientSecret) {
								this.clientSecret = '' + clientSecret;
								this.ready = true;
								this.processing = false;
							} else {
								console.log('Generate Setup Intent Error');
								this.processing = false;
								this.result.result = 'error';
								this.result.error = 'Generate Setup Intent Error';
								this.dismiss();
							}
						},
						error => {
							console.log('Generate Setup Intent Error: ', error);
							this.processing = false;
							this.result.result = 'error';
							this.result.error = error;
							this.dismiss();
						}
					);
				}
			}
 		);

	}

	ngOnDestroy() {
		console.log('ngOnDestroy AddCardModalPage');
		this.sub.unsubscribe();
	}

	dismiss() {
		this.modalController.dismiss(this.result);
	}

	close() {
		this.result.result = 'cancel';
		this.dismiss();
	}

	confirm() {

		this.processing = true;
		console.log('CONFIRM');

		const paymentMethod =  {
			card: this.card,
			billing_details: {
				name: this.customer.name
			}
		};

		this.stripeService.confirmCardSetup(this.clientSecret, paymentMethod).then(
			result => {
				console.log('setup result: ', result);
				if (result.error) {
					// Show error to your customer (e.g., insufficient funds)
					console.log(result.error.message);
					this.modalController.dismiss({result: 'error', error: result.error.message });
				} else {
					// The setup has succeeded. Display a success message.
					console.log('setup successful');
					this.modalController.dismiss({result: 'success'});
				}
				this.processing = false;
			}
		);

	}

	setupStripe() {
		console.log('setupStripe');

		if (this.card) {
			return; // card already mounted
		}

		this.card = this.stripeService.setupStripe(this.cardElement, this.cardChange.bind(this));


	}

	cardChange(event) {
		console.log('Stripe change event: ', event);
		const displayError = this.cardErrors.nativeElement;
		if (typeof event.error !== 'undefined') {
			displayError.textContent = event.error.message;
			this.valid = false;
			console.log('Stripe input NOT VALID');
		} else {
			displayError.textContent = '';
			console.log('Stripe input VALID');
			if (event.complete) {
				console.log('Stripe input COMPLETE');
				this.valid = true;
				this.ref.detectChanges();
			}
		}
	}
}
