import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

import { Place } from '@app/_models/place.model';
import { Cart } from '@app/_models/cart.model';
import { Product } from '@app/_models/product.model';
import { OrderItem } from '@app/_models/order.model';
import { Observable, of } from 'rxjs';

import { CheckoutModalPage } from '@app/checkout-modal/checkout-modal.page';

@Component({
	selector: 'app-cart-modal',
	templateUrl: './cart-modal.page.html',
	styleUrls: ['./cart-modal.page.scss'],
})
export class CartModalPage implements OnInit, OnDestroy {

	/**
	 * Passing a null cart in componentProps will default to the global cart
	 */
	@Input() cart: Cart;

	cart$: Observable<Cart>;
	currency: string;

	cartMessages = {};
	// cartSubscription: any;

	constructor(
		private dataService: DataService,
		private modalController: ModalController,
		private alertController: AlertController,
		private translate: TranslateService
	) {
		console.log('Hello CartModalPage');
		this.translate.get('cart').subscribe( messages => {
			this.cartMessages = messages;
		});
	}

	ngOnInit() {
		console.log('ngOnInit CartModalPage');
		if (this.cart) {
			this.cart$ = of(this.cart);
		} else {
			this.cart$ = this.dataService.cart$;
		}
	}

	ngOnDestroy() {
		console.log('ngOnDestroy CartModalPage');
		// this.cartSubscription.unsubscribe();
	}

	async showCheckoutModal() {
		const modal = await this.modalController.create({
			component: CheckoutModalPage,
			cssClass: 'modal checkout-modal',
			id: 'CheckoutModal',
			componentProps: {
				isModal: true
			}
		});
		// modal.onWillDismiss().then(() => {});
		modal.present();
		const { data } = await modal.onWillDismiss();
		console.log('checkout result: ', data);
		if ('success' === data) {
			this.close();
		}
	}

	close() {
		this.modalController.dismiss({action: 'cancel'}, null, 'CartModal');
	}

}
