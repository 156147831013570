import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models/user.model';

declare var require: any;
const jwtDecode = require('jwt-decode');

@Injectable({ providedIn: 'root' })

/**
 * Data read from server login or local storage:
 * 		{
 * 			"token": JWT token,
 *  		"refresh_token": JWT refresh token,
 * 		}
 *
 * "Decoded token contains the following:
 * 		{
 * 			"iat": 1593971202,
 * 			"roles": [
 *               "ROLE_USER",
 *               "ROLE_PUBLIC_APP_USER"
 *          ],
 * 			"username": "migas@golkee.com",
 * 			"exp": 1594057602,
 * 			"userid": 7,
 * 			"tokenid": "tkn16796570345f021202047692.45711934"
 *  		"fcmToken": for firebase push notification
 *      }
 *
 */
export class AuthenticationService {
	private currentUserSubject: BehaviorSubject<User>;
	public currentUser: Observable<User>;

	constructor(
		private http: HttpClient,
		// private modalController: ModalController
	) {
		// FIXME use native storage?
		console.log('Hello AuthenticationService');
		this.currentUserSubject = new BehaviorSubject<User>(null);
		const user = this.storageGetUser();
		if (user && user.token) {
			this.populateUserFromTokens(user.token, user.refresh_token);
		}
		this.currentUser = this.currentUserSubject.asObservable();
	}

	private getDefaultHeaders() {
		return  new HttpHeaders({
			'X-AUTH-TOKEN': environment.golkee.apiKey,
			Accept: 'application/ld+json',
			'Content-Type': 'application/ld+json'
		});
	}

	private storageGetUser() {
		return JSON.parse(localStorage.getItem('golkee_user'));
	}

	private storageSetUser(user) {
		localStorage.setItem('golkee_user', JSON.stringify(user));
	}

	private populateUserFromTokens(token, refreshToken): User {
		const appUser = this.decodeToken(token, refreshToken);
		console.log('@@@TOKENS [auth]: login: set current user: ', appUser);
		this.currentUserSubject.next(appUser);
		return appUser;
	}

	private decodeToken(token, refreshToken): User {
		try {
			const decodedToken = jwtDecode(token);
			console.log('@@@TOKENS [auth]: decodedToken: ', decodedToken);
			const id = decodedToken.userid;
			const name = decodedToken.username;
			const roles = decodedToken.roles;
			const fcmToken = decodedToken.fcmToken;
			const user = {
				id,
				username: name,
				roles,
				token,
				refresh_token: refreshToken,
				fcmToken
			} as User;
			// console.log('user: ', user);
			return user;
		} catch (e) {
			console.log('@@@TOKENS [auth]: invalid token: ', e);
			throw(e);
		}
	}

	public get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	// used by token.interceptor
	public getAccessToken() {
		const user = this.currentUserValue;
		if (user && user.token) {
			return user.token;
		}
		return null;
	}

	public getRefreshToken() {
		const user = this.currentUserValue;
		console.log('@@@TOKENS [auth]: getRefreshToken: user: ', user);
		if (user && user.refresh_token) {
			return user.refresh_token;
		}
		return null;
	}


	public refreshAccessToken() {
		console.log('@@@TOKENS [auth]: refreshing token');
		const refreshToken = this.getRefreshToken();
		console.log('@@@TOKENS [auth]: refreshToken: ', refreshToken);
		if (refreshToken) {
			return this.http.post<any>(`${environment.golkee.apiHost}/api/token/refresh`, { refresh_token: refreshToken })
			.pipe(map(user => {
				console.log('@@@TOKENS [auth]: refreshAccessToken: user: ', user);
				// store user details and jwt token in local storage to keep user logged in between page refreshes
				// console.log('AuthenticationService: user: ', user);
				if (user && user.token) {
					this.storageSetUser(user);
					const appUser = this.populateUserFromTokens(user.token, user.refresh_token);
					console.log('@@@TOKENS [auth]: refreshAccessToken: appUser: ', appUser);
					return appUser.token;
				} else {
					return null;
				}
			}));

		}
		return of(null);
	}

	login(username: string, password: string) {
		return this.http.post<any>(`${environment.golkee.apiHost}/api/login_check`, { username, password })
		.pipe(map(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			// console.log('AuthenticationService: user: ', user);
			if (user && user.token) {
				this.storageSetUser(user);
				const appUser = this.populateUserFromTokens(user.token, user.refresh_token);
				return appUser;
			} else {
				return null;
			}
		}));
	}

	logout(showWarning = false) {
		console.log('@@@TOKENS [auth]: logout');
		// remove user from local storage to log user out
		localStorage.removeItem('golkee_user');
		this.currentUserSubject.next(null);

		if (showWarning) {
			// this.showLoggedOutModal();
		}

	}

	myCascaisLogin(res: string) {

		const headers = this.getDefaultHeaders();

		return this.http.post<any>(`${environment.golkee.apiHost}/api/connect_my_cascais`, { res }, {headers})
		.pipe(map(user => {
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			// console.log('AuthenticationService: user: ', user);
			console.log('AuthenticationService: myCascaisLogin: user: ' + JSON.stringify(user));
			if (user && user.token) {
				this.storageSetUser(user);
				const appUser = this.populateUserFromTokens(user.token, user.refresh_token);
				return appUser;
			} else {
				return null;
			}
		}));
	}

}
