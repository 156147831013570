import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
	name: 'imageUrl'
})
export class ImageUrlPipe implements PipeTransform {

	transform(value: any, dir): any {
		const mediaServer = environment.golkee.imageBaseUrl;
		if (value) {
			// images uploaded via API
			if (value.filePath) {
				return mediaServer + value.filePath;
			}
			// images uploaded in backend
			if (dir) {
				return mediaServer + dir + '/' + value;
			}
			// images uploaded in backend, no dir
			return mediaServer + value;
		}
		return null;
	}

}
