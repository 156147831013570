import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, ModalController, AlertController } from '@ionic/angular';
import { ApiService } from '@app/_services/api.service';
import { ErrorService } from '@app/_services/error.service';
import { ConfigurationService } from '@app/_services/configuration.service';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.page.html',
	styleUrls: [
		'./forgot-password.page.scss'
	]
})

export class ForgotPasswordPage implements OnInit {

	// Data passed in by componentProps or route snapshot
	@Input() isModal: boolean;

	forgotPasswordForm: FormGroup;
	processing: boolean;
	version: string;

	validationMessages = {
		email: [
			{ type: 'required', message: 'validation.email.required' },
			{ type: 'pattern', message: 'validation.email.pattern' }
		]
	};

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		public menu: MenuController,
		private modalController: ModalController,
		public alertController: AlertController,
		private apiService: ApiService,
		private configurationService: ConfigurationService,
		private errorService: ErrorService
	) {
		this.forgotPasswordForm = new FormGroup({
			email: new FormControl('', Validators.compose([
				Validators.required,
				Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
			]))
		});
	}

	ngOnInit(): void {
		this.version = this.configurationService.getVersion();
		// this.menu.enable(false);
		this.isModal = this.isModal ? this.isModal : this.route.snapshot.queryParams.modal;
	}

	ionViewWillEnter() {
		this.processing = false;
	}

	dismiss() {
		if (this.isModal) {
			this.modalController.dismiss();
		} else {
			this.router.navigate(['/auth/login']);
		}
	}

	recoverPassword(): void {
		console.log(this.forgotPasswordForm.value);
		this.processing = true;
		this.apiService.resetPassword(this.forgotPasswordForm.value)
		.subscribe(data => {
			// execution never reaches here in development mode
			// because an "Http failure during parsing" error will be thrown
			// due to the response having no content
			// this error is intercepted below
			console.log('resetPassword request accepted');
			this.presentSuccessAlert();
		},
		error => {
			console.log('resetPassword error:', error);
			if (error.status === 202) {
				// no error, accepted
				console.log('resetPassword request accepted');
				this.presentSuccessAlert();
			} else {
				// this.error = error;
				this.processing = false;
				this.errorService.showError(error);
			}
		});
	}

	// FIXME translations
	async presentSuccessAlert() {

		let header, message, oktext;
		header = 'Pedido de recuperação efectuado com sucesso';
		message = 'Verifique o seu email e siga as instruções';
		oktext = 'OK';

		const alert = await this.alertController.create({
			cssClass: 'my-custom-class',
			header,
			message,
			buttons: [
 				{
					text: oktext,
					handler: () => {
						this.dismiss();
					}
				}
			]
		});

		await alert.present();
	}

	async goToLogin() {
		this.dismiss();
	}

}
