import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Cart } from '@app/_models/cart.model';
import { Bill } from '@app/_models/bill.model';
declare var Stripe;

@Injectable({
	providedIn: 'root'
})
export class StripeService {

	stripe: any;

	constructor(
	) {
		console.log('Hello StripeService ');
		this.stripe = Stripe(environment.stripe.apiKey);
	}

	setupStripe(cardElement, cardChangeCallback) {
		console.log('setupStripe');

		const elements = this.stripe.elements();
		const style = {
			base: {
				color: '#32325d',
				lineHeight: '24px',
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: 'antialiased',
				fontSize: '16px',
				'::placeholder': {
					color: '#aab7c4'
				}
			},
			invalid: {
				color: '#fa755a',
				iconColor: '#fa755a'
			}
		};

		const card = elements.create('card', { style });
		card.mount(cardElement.nativeElement);
		card.addEventListener('change', cardChangeCallback );

		return card;

	}

	confirmCardSetup(clientSecret, paymentMethod): Promise<any> {
		return this.stripe.confirmCardSetup(clientSecret, { payment_method: paymentMethod });
	}

	// @see https://stripe.com/docs/payments/save-and-reuse#web-create-payment-intent-off-session
	// authenticatePayment(object: Cart|Bill): Promise<any> {
	authenticatePayment(clientSecret, paymentMethodId): Promise<any> {
		// Pass the failed PaymentIntent to your client from your server
		// const clientSecret = object.stripeClientSecret;
		// const paymentMethodId = object.paymentMethodId;
		return this.stripe.confirmCardPayment(clientSecret, { payment_method: paymentMethodId} );
	}

}
