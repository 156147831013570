import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Injectable, NgZone } from '@angular/core';
import { DataService } from '@app/_services/data.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationEnvelope } from '@app/_models/notification.model';

import {
	Plugins,
	PushNotification,
	PushNotificationToken,
	PushNotificationActionPerformed
} from '@capacitor/core';

const { PushNotifications } = Plugins;

import { firebase } from '@firebase/app';
import '@firebase/messaging';

// import * as firebase from 'firebase/app';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class NotificationService {

	// private readonly _token = new BehaviorSubject<PushNotificationToken>(null);
	// tslint:disable-next-line:variable-name
	private readonly _token = new BehaviorSubject<string>(null);
	readonly token$ = this._token.asObservable();

	ready = false;

	constructor(
		private platform: Platform,
		private zone: NgZone,
		public router: Router,
		private dataService: DataService
	) {
		console.log('Hello NotificationService');

		// setup push notification when the customer logs in
		this.dataService.customer$.subscribe(
			customer => {
				if (customer && !this.ready) {
					this.setupNotifications();
				}
			}
		);

		// update customer token on server
		this.token$.subscribe(
			token => {
				if (token) {
					this.updateUserToken();
				}
			}
		);

	}

	/* ====================================================================== */
	/*   S T O R E
	/* ====================================================================== */
	private get token(): string {
		return this._token.getValue();
	}

	private set token(val: string) {
		console.log('### Setting token to ', val);
		this._token.next(val);
	}

	private updateUserToken() {
		console.log('### Updating user token on server...');
		this.dataService.setUserFCMToken(this.token);
	}

	/* ====================================================================== */
	/*   G E N E R I C   P U S H   N O T I F I C A T I O N S
	/* ====================================================================== */
	setupNotifications() {

		console.log('NotificationService: setupNotifications');

		// setup and request permission for push notifications
		// according to platform
		// on success, this.ready will be true
		// and a token will be set

		if (this.platform.is('capacitor')) {
			this.setupMobileNotifications();
		} else {
			this.setupWebNotifications().then(
				() => {
					this.requestPermission();
				}
			);
		}
	}

	/**
	 * NOTE: this must be run inside NgZone, or else changes won't be reflected
	 */
	processNotification(notification) {
		this.zone.run(() => {
			const n = {
				id: notification.id,
				data: notification.data,
				title: notification.title,
				body: notification.body,
				read: false
			} as NotificationEnvelope;
			this.dataService.addNotification(n);
		});
	}

	/**
	 * See Backend:NotificationService:createNotificationByType
	 * Returns a route url
	 */
	processNotificationEnvelope(notificationEnvelope: NotificationEnvelope) {

		const data = notificationEnvelope.data;
		const type = data.type;
		let placeId, orderId, billId;

		console.log('notificationEnvelope: ', notificationEnvelope);

		switch (type) {

			case 'order_ready':
				placeId = data.place_id;
				orderId = data.order_id;
				return ['/app/restaurants/' + placeId, {view: 'requests'}];
				// this.router.navigate(['/app/restaurants/' + placeId]);
				break;

			case 'reservation_accepted':
				placeId = data.place_id;
				return ['/app/restaurants/' + placeId, {view: 'reservations'}];
				// this.router.navigate(['/app/restaurants/' + placeId]);
				break;

			case 'payment_request':
				placeId = data.place_id;
				billId = data.bill_id;
				return 'modal:BillModal:' + billId;
				break;

			default:
				console.log('unknown notification type: ', type);
				break;

		}

		return null;

	}

	/* ====================================================================== */
	/*   M O B I L E   P U S H   N O T I F I C A T I O N S
	/* ====================================================================== */
	setupMobileNotifications() {
		// Request permission to use push notifications
		// iOS will prompt user and return if they granted permission or not
		// Android will just grant without prompting
		PushNotifications.requestPermission().then( result => {
			if (result.granted) {
				// Register with Apple / Google to receive push via APNS/FCM
				PushNotifications.register();
			} else {
				// Show some error
			}
		});

		PushNotifications.addListener('registration',
			(token: PushNotificationToken) => {
				console.log('Push registration success, token: ' + token.value);
				// alert('Push registration success, token: ' + token.value);
				this.ready = true;
				this.token = token.value;
			}
		);

		PushNotifications.addListener('registrationError',
			(error: any) => {
				console.log('Error on registration: ' + JSON.stringify(error));
				// alert('Error on registration: ' + JSON.stringify(error));
			}
		);

		PushNotifications.addListener('pushNotificationReceived',
			(notification: PushNotification) => {
				console.log('Push received: ' + JSON.stringify(notification));
				// alert('Push received: ' + JSON.stringify(notification));
				this.processNotification(notification);
			}
		);

		PushNotifications.addListener('pushNotificationActionPerformed',
			(notification: PushNotificationActionPerformed) => {
				console.log('Push action performed: ' + JSON.stringify(notification));
				// alert('Push action performed: ' + JSON.stringify(notification));
				this.processNotification(notification);
			}
		);

	}

	/* ====================================================================== */
	/*   W E B   P U S H   N O T I F I C A T I O N S
	/* ====================================================================== */
	// @see: https://medium.com/@david.dalbusco/add-web-push-notifications-to-your-ionic-pwa-358f6ec53c6f

	setupWebNotifications(): Promise<void> {

		console.log('NotificationService: setupWebNotifications');
		let firebaseApp;

		if (!firebase.apps.length) {
			firebaseApp = firebase.initializeApp(environment.firebase);
		}

		return new Promise<void>(
			(resolve, reject) => {
				navigator.serviceWorker.ready.then(
					(registration) => {

						console.log('NotificationService: serviceWorker registration: ', registration);

						// Don't crash an error if messaging not supported
						if (!firebase.messaging.isSupported()) {
							resolve();
							return;
						}

						const messaging = firebase.messaging();
						console.log('NotificationService: messaging: ', messaging);

						// Register the Service Worker
						messaging.useServiceWorker(registration);

						// Initialize your VAPI key
						messaging.usePublicVapidKey(
							environment.firebase.vapidKey
						);

						console.log('NotificationService: messaging: ', messaging);

						// Optional and not covered in the article
						// Listen to messages when your app is in the foreground
						messaging.onMessage((payload) => {
							console.log('NotificationService: payload: ', JSON.stringify(payload));
							const notification = payload as NotificationEnvelope; // FIXME map fields
							this.processNotification(notification);
						});

						// @see https://firebase.google.com/docs/cloud-messaging/js/receive
						// messaging.setBackgroundMessageHandler((payload) => {
						// 	console.log('NotificationService: Received background message: ', JSON.stringify(payload));
						// 	const notification = payload as NotificationEnvelope; // FIXME map fields
						// 	this.processNotification(notification);
						// });

						// Optional and not covered in the article
						// Handle token refresh
						messaging.onTokenRefresh(
							() => {
								messaging.getToken().then(
									(refreshedToken: string) => {
										console.log(refreshedToken);
										console.log('NotificationService: refreshedToken: ', refreshedToken);
									})
									.catch((err) => {
										console.error(err);
										console.log('NotificationService: refreshedToken err: ', err);
									}
								);
							}
						);

						resolve();

					},
					(err) => {
						console.log('NotificationService: serviceWorker err: ', err);
						reject(err);
					}
				);
			}
		);
	}

	requestPermission(): Promise<void> {
		return new Promise<void>(
			async (resolve) => {
				// if (!Notification) {
				// 	resolve();
				// 	return;
				// }
				if (!firebase.messaging.isSupported()) {
					resolve();
					return;
				}
				try {
					const messaging = firebase.messaging();
					await messaging.requestPermission();

					const token: string = await messaging.getToken();

					console.log('User notifications token:', token);
					this.ready = true;
					this.token = token;

				} catch (err) {
					// No notifications granted
				}

				resolve();
			}
		);
	}

}
