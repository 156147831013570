import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '@app/_services/loader.service';

@Injectable({
	providedIn: 'root'
})

/**
 * @see https://levelup.gitconnected.com/a-guide-to-managing-loading-state-for-angular-app-api-requests-83b7429d0e3a
 */
export class LoaderInterceptor {

	activeRequests = 0;

	constructor(
		private loaderService: LoaderService
	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		// console.log('### loader: request start');
		if (this.activeRequests === 0) {
			this.loaderService.startLoading();
		}

		this.activeRequests++;

		return next.handle(request).pipe(
			finalize(() => {
				// console.log('### loader: request end');
				this.activeRequests--;
				if (this.activeRequests === 0) {
					this.loaderService.stopLoading();
				}
			})
		);
	}
}
