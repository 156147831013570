import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ImageUrlPipe } from './image-url.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { HumanizeDurationPipe } from './humanize-duration.pipe';
import { Nl2BrPipeModule } from 'nl2br-pipe';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
	Nl2BrPipeModule
  ],
  declarations: [
    ImageUrlPipe,
	FirstNamePipe,
	HumanizeDurationPipe,
  ],
  exports: [
	ImageUrlPipe,
	FirstNamePipe,
	HumanizeDurationPipe,
	Nl2BrPipeModule
  ],
  entryComponents: [],
})
export class PipesModule {}
