import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@app/_helpers/auth.guard';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'intro',
		pathMatch: 'full'
	},
	/* ====================================================================== */
	/*   P U B L I C
	/* ====================================================================== */
	{
		path: 'server-error',
		loadChildren: () => import('./server-error/server-error.module').then( m => m.ServerErrorPageModule)
	},
	{
		path: 'intro',
		loadChildren: () => import('./intro/intro.module').then( m => m.IntroPageModule)
	},

	/* ====================================================================== */
	/*   A U T H
	/* ====================================================================== */
	{
		path: 'auth/login',
		loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule)
	},
	{
		path: 'auth/mycascais-login',
		loadChildren: () => import('./auth/mycascais-login/mycascais-login.module').then( m => m.MycascaisLoginPageModule)
	},
	{
		path: 'auth/logout',
		loadChildren: () => import('./auth/logout/logout.module').then( m => m.LogoutPageModule)
	},
	{
		path: 'auth/signup',
		loadChildren: () => import('./auth/signup/signup.module').then(m => m.SignupPageModule)
	},
	{
		path: 'auth/forgot-password',
		loadChildren: () => import('./auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
	},

	/* ====================================================================== */
	/*   A P P
	/* ====================================================================== */
	{
		path: 'app',
		// canActivate: [AuthGuard],
		loadChildren: () => import('./tabs/tabs.module').then( m => m.TabsPageModule)
	},
	/* ====================================================================== */
	/*   M O D A L S
	/* ====================================================================== */
	{
		path: 'checkout-modal',
		loadChildren: () => import('./checkout-modal/checkout-modal.module').then( m => m.CheckoutModalPageModule)
	},
	{
		path: 'add-card-modal',
		loadChildren: () => import('./add-card-modal/add-card-modal.module').then( m => m.AddCardModalPageModule)
	},
	{
		path: 'browser-scanner',
		loadChildren: () => import('./browser-scanner/browser-scanner.module').then( m => m.BrowserScannerPageModule)
	},
	{
		path: 'delivery-method-modal',
		loadChildren: () => import('./delivery-method-modal/delivery-method-modal.module').then( m => m.DeliveryMethodModalPageModule)
	},
	{
		path: 'reservations-modal',
		loadChildren: () => import('./reservations-modal/reservations-modal.module').then( m => m.ReservationsModalPageModule)
	},
	{
		path: 'reservation-modal',
		loadChildren: () => import('./reservation-modal/reservation-modal.module').then( m => m.ReservationModalPageModule)
	},
	{
		path: 'new-reservation-modal',
		loadChildren: () => import('./new-reservation-modal/new-reservation-modal.module').then( m => m.NewReservationModalPageModule)
	},
	{
		path: 'orders-modal',
		loadChildren: () => import('./orders-modal/orders-modal.module').then( m => m.OrdersModalPageModule)
	},
	{
		path: 'order-modal',
		loadChildren: () => import('./order-modal/order-modal.module').then( m => m.OrderModalPageModule)
	},
	{
		path: 'bills-modal',
		loadChildren: () => import('./bills-modal/bills-modal.module').then( m => m.BillsModalPageModule)
	},
	{
		path: 'bill-modal',
		loadChildren: () => import('./bill-modal/bill-modal.module').then( m => m.BillModalPageModule)
	},
  {
    path: 'payment-modal',
    loadChildren: () => import('./payment-modal/payment-modal.module').then( m => m.PaymentModalPageModule)
  },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
