// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	tag: 'dev',
	golkee: {
		// apiHost: 'https://access.api.demo.golkee.com',
		apiHost: 'http://192.168.1.83:8000',
		apiKey: 'OJoIkYZiST4Udpk9kMyvnvGujO2syz4B',
		imageBaseUrl: 'http://192.168.1.83:8000/media/',
		qrCodeServer: 'http://192.168.1.83:8000/qrcode',
		qrCodesHosts: ['192.168.1.83']
	},
	stripe: {
		apiKey: 'pk_test_51HeM4rAkJxmo8VjZEwrL9Q1hULNjz21KhnLfREDGg3O5jOvzjFxeVg5znemNS8r6HBAXm0lgdMrpTRzqmMOWCJAt006xNjwuBi'
	},
	firebase: {
		apiKey: 'AIzaSyBSiYCawiaa4spf8bHdzDJL6z6gblKEWoQ',
	    authDomain: 'golkee.firebaseapp.com',
	    databaseURL: 'https://golkee.firebaseio.com',
	    projectId: 'golkee',
	    storageBucket: 'golkee.appspot.com',
	    messagingSenderId: '252313122914',
	    appId: '1:252313122914:web:ca15450d18c20e350eb93b',
	    measurementId: 'G-MPRJMMS0BB',
		vapidKey: 'BHMnXjW5GDAZCjvaZEOGG3j48DL527tWDujM9Xz1h2-IP3dCgoZDvlO8KSBSBn0unOzgQT8v4bq8xYjVVoS1N6g'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
