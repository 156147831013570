import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
// import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';

import { ShellModule } from '@app/_shell/shell.module';

import { CheckboxWrapperComponent } from './checkbox-wrapper/checkbox-wrapper.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { ProductOptionComponent } from './product-option/product-option.component';
import { CartComponent } from './cart/cart.component';
import { NotificationIconComponent } from './notification-icon/notification-icon.component';
import { PaymentMethodsComponent } from './payment-methods/payment-methods.component';

@NgModule({
  imports: [
    CommonModule,
	// SharedModule,
	TranslateModule,
    FormsModule,
    ShellModule,
    IonicModule
  ],
  declarations: [
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
	ProductOptionComponent,
	CartComponent,
	NotificationIconComponent,
	PaymentMethodsComponent
  ],
  exports: [
    // ShellModule,
    CheckboxWrapperComponent,
    ShowHidePasswordComponent,
	ProductOptionComponent,
	CartComponent,
	NotificationIconComponent,
	PaymentMethodsComponent
  ]
})
export class ComponentsModule {}
