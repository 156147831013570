import { Component, OnInit } from '@angular/core';
import { ModalController, LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

import { LocationService } from '@app/_services/location.service';
import { DataService } from '@app/_services/data.service';

import { Customer, CustomerAddress } from '@app/_models/customer.model';
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Plugins } from '@capacitor/core';

const { Geolocation } = Plugins;

@Component({
	selector: 'app-location-modal',
	templateUrl: './location-modal.page.html',
	styleUrls: ['./location-modal.page.scss'],
})

export class LocationModalPage implements OnInit {

	customer: Customer;
	geocodeError: any;
	geocodeResults: any[];
	country = ''; // FIXME
	addressRecord = {
		address: '',
		streetNumber: '',
		floor: '',
		locality: '',
		zip_code: '',
		country: '',
		formattedAddress: ''
	};

	addressForm: FormGroup;
	validationMessages = {
		address: [
			{ type: 'required', message: 'Adress is required.' }
		],
		floor: [
			{ type: 'required', message: 'Floor is required.' }
		],
		streetNumber: [
			{ type: 'required', message: 'Street number is required.' }
		],
		locality: [
			{ type: 'required', message: 'Locality is required.' }
		]
	};
	submitted = false;
	searching = false;
	locating = false;
	locatingMessage = 'location.message.locating';
	locatingError = 'location.errors.generic';
	loadingElement: any;
	locationType: string;

	constructor(
		private modalController: ModalController,
		private loadingController: LoadingController,
		private translate: TranslateService,
		private dataService: DataService,
		private locationService: LocationService
	) {
		console.log('Hello LocationModalPage');
		this.translate.get(this.locatingMessage).subscribe(value => { this.locatingMessage = value; });
		this.translate.get(this.locatingError).subscribe(value => { this.locatingError = value; });

	}

	ngOnInit() {
		this.customer = this.dataService.getCustomerValue();
		this.country  = this.customer && this.customer.country ? this.customer.country : 'PT'; // FIXME get from customer / environment

		this.addressForm = new FormGroup({
			address: new FormControl(this.addressRecord.address, []), // , [Validators.required]),
			streetNumber: new FormControl(this.addressRecord.streetNumber, []), // [Validators.required]),
			floor: new FormControl(this.addressRecord.floor, []),
			locality: new FormControl(this.addressRecord.locality, [Validators.required]),
		});

		this.onChanges();
		this.createLoader();
	}

	onChanges(): void {
		console.log('onChanges called');
		this.addressForm.valueChanges.subscribe(val => {
			this.submitted = false;
		});
	}

	selectLocationType(locationType) {
		this.locationType = locationType;
		if ('auto' === locationType) {
			this.autoLocation();
		} else {
			this.startSearchingLocation();
		}
	}

	startSearchingLocation() {
		this.searching = true;
	}

	searchLocation() {

		this.submitted = true;

		const address       = this.addressForm.get('address').value;
		const streetNumber  = this.addressForm.get('streetNumber').value;
		const floor 	    = this.addressForm.get('floor').value;
		const locality      = this.addressForm.get('locality').value;

		const formattedAddress = address + ' ' + streetNumber + ' ' + floor + ', ' + locality;

		const country = this.country;

		this.addressRecord.address = address;
		this.addressRecord.streetNumber = streetNumber;
		this.addressRecord.floor = floor;
		this.addressRecord.locality = locality;
		this.addressRecord.zip_code = ''; // zip_code; // FIXME
		this.addressRecord.country = country; // FIXME
		this.addressRecord.formattedAddress = formattedAddress;


		const promise = this.locationService.forwardGeocode(formattedAddress, country)
			.then((result) => {
					console.log('geocode result: ', result);
					this.geocodeResults = result;
				}
			)
			.catch((error: any) => {
				console.log('geocode error: ', error);
				this.geocodeError = error;
				this.geocodeResults = [];
			}
		);

	}

	autoLocation() {

		this.locating = true;
		this.presentLoader();
		Geolocation.getCurrentPosition().then(position => {

			console.log('position: ', position);

			if (position && position.coords) {
				const lat = position.coords.latitude;
				const lng = position.coords.longitude;
				const formattedAddress = lat + ', ' + lng;
				this.addAddress(lat, lng, formattedAddress);
			} else {
				console.log(this.locatingError);
			}

		}).catch((error) => {
			console.log(this.locatingError, error);

		}).finally(() => {
			this.dismissLoader();
			this.locating = false;
		});

	}

	addAddressFromGeocodeResult(geocodeResult) {
		const lat = geocodeResult.geometry.location.lat();
		const lng = geocodeResult.geometry.location.lng();
		const formattedAddress = geocodeResult.formatted_address;
		this.addAddress(lat, lng, formattedAddress);
	}

	addAddress(lat, lng, formattedAddress) {
		this.searching = false;

		const customerAddress = {
			customer: this.customer,
			address: this.addressRecord.address,
			streetNumber: this.addressRecord.streetNumber,
			floor: this.addressRecord.floor,
			locality: this.addressRecord.locality,
			zipCode: '', // FIXME
			country: this.country, // FIXME
			lat: '' + lat,
			lng: '' + lng,
			formattedAddress, // this.addressRecord.formattedAddress,
		} as CustomerAddress;

		this.dataService.addCustomerAddress(customerAddress);
		setTimeout(() => {
			this.close();
		}, 500);

	}

	useAddress(address: CustomerAddress) {
		console.log('using address: ', address);
		this.dataService.useCustomerAddress(address);
		setTimeout(() => {
			this.close();
		}, 0); // FIXME
	}

	close() {
		this.modalController.dismiss();
	}

	async createLoader() {
		this.loadingElement = await this.loadingController.create({
			message: this.locatingMessage
		});
	}

	async presentLoader() {
		await this.loadingElement.present();
	}

	async dismissLoader() {
		if (this.loadingElement) {
			await this.loadingElement.dismiss();
		}
	}

}
