import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Slot } from '@app/_models/slot.model';
import { Place } from '@app/_models/place.model';
// import { OrderItem } from '@app/_models/order.model';

@Component({
	selector: 'app-slot-modal',
	templateUrl: './slot-modal.page.html',
	styleUrls: ['./slot-modal.page.scss'],
})
export class SlotModalPage implements OnInit {

	// Data passed in by componentProps
	@Input() slot: Slot;
	@Input() place: Place;
	@Input() action: string;

	cancelTitle: string;
	cancelMessage: string;
	dismissButton: string;
	confirmButton: string;

	submitted  = false;
	processing = false;
	reserved   = false;
	canceled   = false;
	error      = null;
	inUse     = false;

	constructor(
		private modalController: ModalController,
		private alertController: AlertController,
		private dataService: DataService,
		private translate: TranslateService
	) {
		console.log('Hello SlotModalPage');
		this.translate.get('reservations.label.cancel_warning').subscribe(value => { this.cancelTitle = value; });
		this.translate.get('reservations.text.confirm_cancelation').subscribe(value => { this.cancelMessage = value; });
		this.translate.get('reservations.buttons.dismiss').subscribe(value => { this.dismissButton = value; });
		this.translate.get('reservations.buttons.confirm_cancelation').subscribe(value => { this.confirmButton = value; });
	}

	ngOnInit() {
		this.inUse = this.slot.in_use;
	}

	reserveSlot() {
		this.processing = true;
		this.submitted = true;
		this.dataService.reserveSlot(this.place, this.slot).subscribe(
			reserved => {
				console.log('reserved: ', reserved);
				this.reserved = reserved;
				this.processing = false;
			},
			error => {
				console.log('error: ', error);
				if (error.error && error.error['hydra:description']) {
					this.error = error.error['hydra:description'];
				} else if (error.message) {
					this.error = error.message;
				} else {
					this.error = 'unknown_error';
				}
				this.processing = false;
			}
		);
		// this.close();
	}

	doCancelSlot() {
		this.processing = true;
		this.submitted = true;
		this.dataService.cancelSlot(this.place, this.slot).subscribe(
			canceled => {
				console.log('canceled: ', canceled);
				this.canceled = canceled;
				this.processing = false;
			},
			error => {
				console.log('error: ', error);
				if (error.error && error.error['hydra:description']) {
					this.error = error.error['hydra:description'];
				} else if (error.message) {
					this.error = error.message;
				} else {
					this.error = 'unknown_error';
				}
				this.processing = false;
			}
		);

	}

	async cancelSlot() {
		const alert = await this.alertController.create({
			header: this.cancelTitle,
			message: this.cancelMessage,
			buttons: [
				{
					text: this.dismissButton,
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {
						console.log('Confirm Cancel: blah');
						this.close();
					}
				}, {
					text: this.confirmButton,
					handler: () => {
						console.log('Confirm Okay');
						this.doCancelSlot();
						// this.close();
					}
				}
			]
		});
		await alert.present();
	}

	close() {
		this.modalController.dismiss();
	}

}
