import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, AlertController } from '@ionic/angular';
import { ApiService } from '@app/_services/api.service';
import { DataService } from '@app/_services/data.service';
// import { StripeService } from '@app/_services/stripe.service';
import { ErrorService } from '@app/_services/error.service';

import { Customer, CustomerCard } from '@app/_models/customer.model';
import { Cart } from '@app/_models/cart.model';
import { Product } from '@app/_models/product.model';
import { Order, OrderItem } from '@app/_models/order.model';
import { Observable, combineLatest } from 'rxjs';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

// import { AddCardModalPage } from '@app/add-card-modal/add-card-modal.page';
//
// declare var Stripe;

// enum PaymentMethods {
// 	in_store = 'in_store',
// 	stripe = 'stripe'
// }

@Component({
  selector: 'app-checkout-modal',
  templateUrl: './checkout-modal.page.html',
  styleUrls: ['./checkout-modal.page.scss'],
})
export class CheckoutModalPage implements OnInit, OnDestroy {

	customer$: Observable<Customer>;
	cart$: Observable<Cart>;
	customer: Customer;
	cart: Cart;
	cards: CustomerCard[];
	deliveryMethod$: Observable<string>;
	deliveryMethod: string;
	deliverySeatCode$: Observable<string>;
	// currency: string;
	// stripe: any;
	currentCardId: string;
	processing = false;
	valid = false;

	checkoutMessages = {};
	sub: any;

	paymentMethod: string;

	constructor(
		private router: Router,
		private modalController: ModalController,
		private alertController: AlertController,
		private apiService: ApiService,
		private dataService: DataService,
		// private stripeService: StripeService,
		private errorService: ErrorService,
		private translate: TranslateService
	) {
		console.log('Hello CheckoutModalPage');
		this.translate.get('checkout').subscribe( messages => {
			this.checkoutMessages = messages;
		});
	}

	ngOnInit() {

		console.log('ngOnInit CheckoutModalPage');

		this.customer$ = this.dataService.customer$;
		this.cart$ = this.dataService.cart$;
		this.deliveryMethod$ = this.dataService.deliveryMethod$;

		this.sub = combineLatest([this.customer$, this.cart$, this.deliveryMethod$])
			.subscribe(([customer, cart, deliveryMethod]) => {
				if (customer) {
					this.customer = customer;
					// if (customer.id) {
					// 	// this.getCustomerCards();
					// }
				}
				if (cart) {
					this.cart = cart;
					this.validate(null);
				}
				if (deliveryMethod) {
					this.deliveryMethod = deliveryMethod;
				}
			}
		);

	}

	ngOnDestroy() {
		console.log('ngOnDestroy CheckoutModalPage');
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	/* ====================================================================== */
	/*   Payment Methods and validation component
	/* ====================================================================== */
	onPaymentMethodChange(event) {
		console.log('Payment Methods Component: paymentMethod: ', event);
		this.paymentMethod = event;
	}

	onPaymentMethodIdChange(event) {
		console.log('Payment Methods Component: currentCardId: ', event);
		this.currentCardId = event;
	}

	onPaymentMethodValidation(event) {
		console.log('Payment Methods Component: valid: ', event);
		this.validate(event);
	}

	onPaymentAuthentication(event) {
		console.log('Payment Methods Component: onPaymentAuthentication: ', event);
		if ('success' === event) {
			// see onPaymentIntent
		} else {
			this.errorService.showError('card_authentication_error');
		}
	}

	onPaymentIntent(event) {
		// this is returned after succesful card authentication
		console.log('Payment Methods Component: onPaymentIntent: ', event);
		if (event && event.id) {
			this.checkPaymentStatusAfterAuthentication(event.id);
		}
	}


	validate(paymentMethodValidation) {
		if (this.cart.payWhenOrdering === false) {
			this.valid = true;
		} else {
			this.valid = paymentMethodValidation;
		}
	}

	/* ====================================================================== */
	/*   Place the Order
	/*   Only if the payment method is valid
	/* ====================================================================== */
	confirm() {

		if (!this.valid) {
			return;
		}

		this.processing = true;
		// this.dataService.placeAllCartOrders(PaymentMethods.stripe, this.currentCardId).subscribe(
		this.dataService.placeAllCartOrders(this.paymentMethod, this.currentCardId).subscribe(
			placed => {
				if (placed) {
					this.showSuccessMessage();
					this.processing = false;
					this.close('success');
				} else {
					// FIXME error message?
					this.processing = false;
					// this.close('error');

					// the updated cart should contain a authentication_required paymentError
					// which will  update the payment methods component
					// with a button to authenticate or chose another card
				}
			},
			error => {
				// error already shown by data service
				this.processing = false;
				this.close('error');
			}
		);

	}

	checkPaymentStatusAfterAuthentication(paymentToken) {
		this.dataService.checkCartPaymentStatus(paymentToken).subscribe(
			confirmed => {
				this.processing = false;

				if (confirmed) {
					this.showSuccessMessage();
					this.close('success');
				} else {
					this.errorService.showError('unknown_error');
					this.processing = false;
				}
			},
			error => {
				this.errorService.showError(error);
				this.processing = false;
				// this.close('error');
			}
		);
	}

	/* ====================================================================== */
	//
	//
	// changePaymentMethod(event) {
	// 	console.log('changePaymentMethod: paymentMethod: ', this.paymentMethod);
	// 	this.validate();
	// }
	//
	// getCustomerCards() {
	// 	this.cards = [];
	// 	this.currentCardId = '';
	// 	this.apiService.getCustomerCards(this.customer).subscribe(
	// 		data => {
	// 			console.log('paymentMethods: ', data);
	// 			if (data && data['hydra:member']) {
	// 				const response = data['hydra:member'];
	// 				if (response.length > 1 && response[0] === 'list') {
	// 					const paymentMethods = response[1];
	// 					paymentMethods.forEach(
	// 						paymentMethod => {
	// 							const card = {
	// 								id: paymentMethod.id,
	// 								brand: paymentMethod.card.brand,
	// 								country: paymentMethod.card.country,
	// 								exp_month: paymentMethod.card.exp_month,
	// 								exp_year: paymentMethod.card.exp_year,
	// 								fingerprint: paymentMethod.card.fingerprint,
	// 								funding: paymentMethod.card.funding,
	// 								last4: paymentMethod.card.last4,
	// 							} as CustomerCard;
	// 							this.cards.push(card);
	// 						}
	// 					);
	// 					this.currentCardId = this.cards.length > 0 ? this.cards[0].id : null;
	// 					this.validate();
	// 				}
	// 			}
	// 		}
	// 	);
	// }
	//
	// async addCard() {
	// 	const modal = await this.modalController.create({
	// 		component: AddCardModalPage,
	// 		cssClass: 'cart-modal'
	// 	});
	// 	modal.onDidDismiss().then(
	// 		response => {
	// 			console.log('AddCardModalPage dismiss: ', response);
	// 			if (response.data != null) {
	// 				if (response.data.result === 'success') {
	// 					console.log('setup successful');
	// 					this.getCustomerCards();
	// 				} else if (response.data.result === 'cancel') {
	// 					console.log('setup canceled');
	// 				} else {
	// 					this.errorService.showError(response.data.error);
	// 				}
	// 			}
	// 		}
	// 	);
	// 	await modal.present();
	// }
	//
	// validate() {
	// 	this.valid = false;
	// 	console.log('### CART: ', this.cart);
	// 	// assert cart exists
	// 	if (this.cart) {
	// 		// assert cart has orders
	// 		if (this.cart.customerOrders.length > 0) {
	// 			// assert customer exists
	// 			if (this.customer) {
	//
	// 				switch (this.paymentMethod) {
	//
	// 					case 'in_store':
	// 					this.valid = true;
	// 					break;
	//
	// 					case 'credit_card':
	// 					// assert customer has a valid card selected
	// 					// FIXME sync with customer
	// 					if (this.currentCardId) {
	// 						// if (this.customer.currentCard) {
	// 						// FIXME other validations: location, etc
	// 						this.valid = true;
	// 					}
	// 					break;
	//
	// 				}
	// 			}
	// 		}
	// 	}
	// }
	//
	//
	//
	// /**
	//  * Authenticate a payment that failed due to authentication_required
	//  * @see https://stripe.com/docs/payments/save-and-reuse#web-create-payment-intent-off-session
	//  */
	// authenticatePayment() {
	// 	this.processing = true;
	// 	this.stripeService.authenticatePayment(this.cart)
	// 		.then(
	// 			(result) => {
	//
	// 				console.log('authenticatePayment result: ', result);
	//
	// 				if (result) {
	// 					if (result.error) {
	// 						// Show error to your customer
	// 						console.log('authenticatePayment error: ', result.error);
	// 						console.log('authenticatePayment error (1): ', result.error.message);
	// 					} else {
	// 						if (result.paymentIntent.status === 'succeeded') {
	// 							// The payment is complete!
	// 							console.log('payment succesful');
	//
	// 							this.dataService.checkPaymentStatus().subscribe(
	// 								confirmed => {
	// 									if (confirmed) {
	// 										this.showSuccessMessage();
	// 										this.processing = false;
	// 										this.close('success');
	// 									} else {
	// 										// FIXME error message?
	// 										this.processing = false;
	// 										this.close('error');
	// 									}
	// 								},
	// 								error => {
	// 									// error already shown by data service
	// 									this.processing = false;
	// 									this.close('error');
	// 								}
	// 							);
	//
	// 						} else {
	// 							console.log('authenticatePayment wrong status: ', result.paymentIntent.status);
	// 							this.close('error');
	// 						}
	// 					}
	// 				} else {
	// 					console.log('authenticatePayment error (2): empty result'); // FIXME
	// 					this.close('error');
	// 				}
	// 			}
	// 		)
	// 		.catch(err => {
	// 			console.log('authenticatePayment error (3): ', err); // FIXME
	// 			this.close('error');
	// 		});
	// }

	async showSuccessMessage() {
		// FIXME TODO
		// Perfom PayPal or Stripe checkout process
		const alert = await this.alertController.create({
			// tslint:disable-next-line:no-string-literal
			header: this.checkoutMessages['text']['success']['title'],
			// tslint:disable-next-line:no-string-literal
			message: this.checkoutMessages['text']['success']['body'],
			buttons: ['OK']
		});
		alert.present().then(() => {});
	}

	close(result) {
		this.modalController.dismiss(result, null, 'CheckoutModal');
	}

}
