import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, zip, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { ErrorService } from './error.service';
import { AuthenticationService } from './authentication.service';

import { County } from '@app/_models/county.model';
import { Theme } from '@app/_models/theme.model';
import { Place, DeliveryMethodOptions } from '@app/_models/place.model';
import { Slot } from '@app/_models/slot.model';
import { Customer, CustomerAddress, CustomerRequests, CustomerReservations } from '@app/_models/customer.model';
import { Cart } from '@app/_models/cart.model';
import { Order, OrderItem } from '@app/_models/order.model';
import { Notification, NotificationEnvelope } from '@app/_models/notification.model';
import { GroupReservation } from '@app/_models/reservation.model';
import { User } from '@app/_models/user.model';
import { Product } from '../_models/product.model';
import { Bill } from '../_models/bill.model';
import { Payment } from '../_models/payment.model';
import { Checkout } from '../_models/checkout.model';

/**
 * @see https://coryrylan.com/blog/angular-observable-data-services
 * @see https://dev.to/avatsaev/simple-state-management-in-angular-with-only-services-and-rxjs-41p8
 */

@Injectable({
	providedIn: 'root'
})
export class DataService {

	// tslint:disable-next-line:variable-name
	private readonly _user = new BehaviorSubject<User>(null);
	readonly user$ = this._user.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _customer = new BehaviorSubject<Customer>(null);
	readonly customer$ = this._customer.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _currentLocation = new BehaviorSubject<CustomerAddress>(null);
	readonly currentLocation$ = this._currentLocation.asObservable();

	// private currentAddress = null;

	// tslint:disable-next-line:variable-name
	private readonly _themes = new BehaviorSubject<Theme[]>([]);
	readonly themes$ = this._themes.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _currentTheme = new BehaviorSubject<Theme>(null);
	readonly currentTheme$ = this._currentTheme.asObservable();

	private  currentThemeId: number = null;
	private  currentThemeSlug: string = null;

	// tslint:disable-next-line:variable-name
	private readonly _places = new BehaviorSubject<Place[]>([]);
	readonly places$ = this._places.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _currentPlace = new BehaviorSubject<Place>(null);
	readonly currentPlace$ = this._currentPlace.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _searchResults = new BehaviorSubject<any[]>([]);
	readonly searchResults$ = this._searchResults.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _slots = new BehaviorSubject<Slot[]>([]);
	readonly slots$ = this._slots.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _counties = new BehaviorSubject<County[]>([]);
	readonly counties$ = this._counties.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _currentCounty = new BehaviorSubject<County>(null);
	readonly currentCounty$ = this._currentCounty.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _ready = new BehaviorSubject<boolean>(false);
	readonly ready$ = this._ready.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _cart = new BehaviorSubject<Cart>(null);
	readonly cart$ = this._cart.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _tabMode = new BehaviorSubject<string>(null);
	readonly tabMode$ = this._tabMode.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _notifications = new BehaviorSubject<Notification[]>([]);
	readonly notifications$ = this._notifications.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _unreadNotifications = new BehaviorSubject<number>(0);
	readonly unreadNotifications$ = this._unreadNotifications.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _totalUnreadNotifications = new BehaviorSubject<number>(0);
	readonly totalUnreadNotifications$ = this._totalUnreadNotifications.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _deliveryMethod = new BehaviorSubject<string>(null);
	readonly deliveryMethod$ = this._deliveryMethod.asObservable();

	// tslint:disable-next-line:variable-name
	private readonly _deliverySeatCode = new BehaviorSubject<string>(null);
	readonly deliverySeatCode$ = this._deliverySeatCode.asObservable();

	// tslint:disable-next-line:variable-name
	// private readonly _reservations = new BehaviorSubject<GroupReservation[]>([]);
	// readonly reservations$ = this._reservations.asObservable();

	constructor(
		private apiService: ApiService,
		private errorService: ErrorService,
		private authenticationService: AuthenticationService
	) {
		console.log('Hello DataService');

		// load places by county when county changes
		// FIXME this never happens now, remove?
		this.currentCounty$.subscribe(
			county => {
				if (county) {
					this.loadPlacesByCounty(county);
				}
			}
		);

		// load places by location when customer address changes
		this.customer$.subscribe(
			customer => {
				if (customer && customer.currentAddress && customer.currentAddress !== this.currentLocation) {
					this.currentLocation = customer.currentAddress;
					const locationCoords = {
						coords: {
							latitude:  this.currentLocation.lat,
							longitude: this.currentLocation.lng
						}
					};
					this.loadPlacesByLocation(locationCoords);
				}
			}
		);

		// load data on user change
		// invalidate store on logout
		this.authenticationService.currentUser.subscribe(
			user => {
				// console.log('### USER: ', user);
				if (user) {
					this.user = user;
					this.loadCustomer();
					this.refreshNotifications();
					// FIXME if a temporary customer exists merge data such as addresses carts, etc
					// this.loadInitialData();
				} else {
					this.invalidateStore();
					// in case there was an authentication or server error
					// this initial data was not loaded
					this.loadInitialData();
				}
			}
		);

		// set store ready when themes are loaded
		this.themes$.subscribe(
			themes => {
				if (themes) {
					if (this.currentThemeSlug) {
						this.currentTheme = themes.find( x => x.slug === this.currentThemeSlug );
					}
					// CHANGED: fetching themes is enough for store to be ready
					this.ready = true;
				}
				// this.counties$.subscribe(
				//
				// 	counties => {
				//
				// 		this.currentCounty$.subscribe(
				// 			county => {
				// 				if (county) {
				// 					this.loadPlacesByCounty(county);
				// 				}
				// 			}
				// 		);
				// 	}
				// );
			}
		);

		// load initial data when starting without user... FIXME
		this.loadInitialData();

	}

 	private loadInitialData() {
		this.loadThemes();
		this.loadCounties();

		// FIXME merge county with address
		// this.currentCounty = JSON.parse(localStorage.getItem('golkee_current_county'));

		/**
		 * FIXME
		 * if load counties loads only one county
		 * it will load places by county
		 * we need to make sure themes have already been loaded
		 */
		// this.loadCounties();
	}

	public invalidateStore() {
		this.user = null;
		this.customer = null;
		this.currentLocation = null;
		this.places = [];
		this.slots = [];
		this.themes.forEach(
			theme => {
				theme.enabled = false;
			}
		);
		this.cart = null;
		this.notifications = [];
		this.unreadNotifications = 0;
		this.totalUnreadNotifications = 0;
		this.deliveryMethod = null;
		this.deliverySeatCode = null;
	}

	/* ====================================================================== */
	/*   R E A D Y
	/* ====================================================================== */
	private get ready(): boolean {
		return this._ready.getValue();
	}

	private set ready(val: boolean) {
		console.log('### Setting ready to ', val);
		this._ready.next(val);
	}

	/* ====================================================================== */
	/*   T A B   M O D E
	/* ====================================================================== */
	private get tabMode(): string {
		return this._tabMode.getValue();
	}

	private set tabMode(val: string) {
		console.log('### Setting tabMode to ', val);
		this._tabMode.next(val);
	}

	public setTabMode(mode: string) {
		this.tabMode = mode;
	}

	/* ====================================================================== */
	/*   S E A R C H   R E S U L T S
	/* ====================================================================== */
	private get searchResults(): Place[] {
		return this._searchResults.getValue();
	}

	private set searchResults(val: Place[]) {
		console.log('### Setting searchResults to ', val);
		this._searchResults.next(val);
	}

	/* ====================================================================== */
	/*   C U R R E N T   L O C A T I O N
	/* ====================================================================== */
	private get currentLocation(): CustomerAddress {
		return this._currentLocation.getValue();
	}

	private set currentLocation(val: CustomerAddress) {
		console.log('### Setting currentLocation to ', val);
		this._currentLocation.next(val);
	}

	/* ====================================================================== */
	/*   U S E R
	/* ====================================================================== */
	private get user(): User {
		return this._user.getValue();
	}

	private set user(val: User) {
		console.log('### Setting user to ', val);
		this._user.next(val);
	}

	/* ====================================================================== */
	/*   C U S T O M E R
	/* ====================================================================== */
	// the getter will return the last value emitted in _customer subject
	private get customer(): Customer {
		return this._customer.getValue();
	}

	// assigning a value to this.customer will push it onto the observable
	// and down to all of its subscribers (ex: this.customer = null)
	// we also add the customer to the in-memory object
	private set customer(val: Customer) {
		console.log('### Setting customer to ', val);
		this._customer.next(val);
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
	// creates a temporary customer for holding addresses, etc
	private createCustomer(): Customer {
		const customer = {
			'@id': '',
			'@type': 'customer',
			id: '',
			uid: '',
			nif: '',
			name: '',
			country: '',
			addresses: [],
			currentAddress: null,
			cards: [],
			currentCard: null,
			currentSlotReservations: [],
			currentGroupReservations: [],
			access: false,
			accessStatus: '',
			image: ''
		} as Customer;
		return customer;
	}

	public getCustomerValue(): Customer {
		return this.customer;
	}

	public loadCustomer(refresh = false) {
		console.log('@@@ loadCustomer');
		if (refresh || !this.customer || !this.customer.id) {
			this.apiService.getCustomer().subscribe(
				customer => {

					if (customer) {

						this.customer = customer;

						if (customer.currentCart) {
							this.cart = customer.currentCart;

							// get delivery method from first order (FIXME)
							if (this.cart.customerOrders && this.cart.customerOrders.length > 0) {
								const firstOrder = this.cart.customerOrders[0] as Order;
								const orderDeliveryMethod = firstOrder.deliveryMethod;
								const orderDeliverySeatCode = firstOrder.deliverySeatCode;
								this.setDeliveryMethod(orderDeliveryMethod, orderDeliverySeatCode).subscribe();
							}

						} else {
							this.cart = null;
						}

						// if (customer.currentGroupReservations) {
						// 	this.reservations = customer.currentGroupReservations;
						// } else {
						// 	this.reservations = [];
						// }

					} else {
						this.customer = null;
						this.cart = null;
					}

				},
				error => {
					console.log('getCustomer error: ', error);
					this.errorService.showError(error);
				}
			);
		}
	}

	public refreshCustomer() {
		this.loadCustomer(true);
	}

	public addCustomerAddress(customerAddress: CustomerAddress) {

		let customer  = this.customer;

		// assert we have a persisted customer
		if (customer && customer.id) {

			let addresses = customer.addresses;
			this.apiService.addCustomerAddress(customerAddress).subscribe(
				newCustomerAddress => {
					addresses = [...addresses, newCustomerAddress];
					customer.addresses = addresses;
					customer.currentAddress = newCustomerAddress;
					this.customer = customer;
				},
				error => {
					console.log('addCustomerAddress error: ', error);
					this.errorService.showError(error);
				}
			);

		// otherwise use the temporary customer
		} else {

			if (!customer) {
				customer = this.createCustomer();
			}

			customer.addresses = [customerAddress];
			customer.currentAddress = customerAddress;
			this.customer = customer;

		}

	}

	public useCustomerAddress(customerAddress: CustomerAddress) {
		const customer  = this.customer;

		this.apiService.useCustomerAddress(customerAddress).subscribe(
			currCustomerAddress => {
				customer.currentAddress = currCustomerAddress;
				this.customer = customer;
			},
			error => {
				console.log('useCustomerAddress error: ', error);
				this.errorService.showError(error);
			}
		);
	}

	public setUserFCMToken(token) {
		const user  = this.user;
		// assert we have a persisted user
		if (user && user.id) {
			this.apiService.updateUserToken(user, token).subscribe(
				data => {
					if (data) {
						const modUser = data as User;
						user.fcmToken = modUser.fcmToken;
						this.user = user;
					}
				}
			);
		}
	}

	/* ====================================================================== */
	/*   C O U N T I E S
	/* ====================================================================== */
	private get counties(): County[] {
		return this._counties.getValue();
	}

	private set counties(val: County[]) {
		console.log('### Setting counties to ', val);
		this._counties.next(val);
	}

	private get currentCounty(): County {
		return this._currentCounty.getValue();
	}

	private set currentCounty(val: County) {
		console.log('@@@ Setting currentCounty to ', val);
		this._currentCounty.next(val);
	}

	public setCurrentCounty(val: County) {
		localStorage.setItem('golkee_current_county', JSON.stringify(val));
		this.currentCounty = val;
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	loadCounties() {
		console.log('@@@ loadCounties');
		if (!(this.counties.length > 0)) {
			this.apiService.getActiveCounties().subscribe(
				data => {
					if (data && data['hydra:member']) {
						this.counties = data['hydra:member'];
						// if (!this.currentCounty && this.counties.length === 1) {
						// 	this.currentCounty = this.counties[0];
						// }
					}
				},
				error => {
					console.log('Could not load counties.');
					console.log('DataService error: ', error);
					this.errorService.showError(error);
				}
			);
		}
	}


	/* ====================================================================== */
	/*   T H E M E S
	/* ====================================================================== */
	private get themes(): Theme[] {
		return this._themes.getValue();
	}

	private set themes(val: Theme[]) {
		console.log('### Setting themes to ', val);
		this._themes.next(val);
	}

	private get currentTheme(): Theme {
		return this._currentTheme.getValue();
	}

	private set currentTheme(val: Theme) {
		console.log('### Setting currentTheme to ', val);
		this._currentTheme.next(val);
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

	public getThemeById(id) {
		return this.themes.find( x => x.id === id );
		//
		// console.log('Dataservice: getTheme: id: ', id);
		// console.log('Dataservice: getTheme: this.themes: ', this.themes);
		// const results = this.themes.filter( x => x.id === id);
		// console.log('Dataservice: getTheme: results: ', results);
		// return results.length > 0 ? results[0] : null;
	}

	public getThemeBySlug(slug) {
		return this.themes.find( x => x.slug === slug );
	}

	public setCurrentThemeById(themeId: number) {
		// console.log('### setCurrentThemeById ', themeId);
		this.currentThemeId = themeId;
		if (this.themes) {
			this.currentTheme = this.getThemeById( themeId );
			// this.currentTheme = this.themes.find( x => x.id === themeId );
		}
	}

	public setCurrentThemeBySlug(themeSlug: string): number {
		// console.log('### setCurrentThemeBySlug ', themeSlug);
		this.currentThemeSlug = themeSlug;
		if (this.themes) {
			this.currentTheme = this.getThemeBySlug( themeSlug );
			// this.currentTheme = this.themes.find( x => x.slug === themeSlug );
			this.currentThemeId = this.currentTheme ? this.currentTheme.id : null;
			return this.currentThemeId;
		}
		return 0; // FIXME check
	}

	loadThemes() {
		console.log('@@@ loadThemes');
		if (!(this.themes.length > 0)) {
			this.apiService.getThemes().subscribe(
				data => {
					if (data && data['hydra:member']) {
						this.themes = data['hydra:member'];
					}
				},
				error => {
					console.log('Could not load themes.');
					console.log('DataService error: ', error);
					this.errorService.showError(error);
				}
			);
		}
	}


	/* ====================================================================== */
	/*   P L A C E S
	/* ====================================================================== */
	private get places(): Place[] {
		return this._places.getValue();
	}

	private set places(val: Place[]) {
		console.log('### Setting places to ', val);
		this._places.next(val);
	}

	private get currentPlace(): Place {
		return this._currentPlace.getValue();
	}

	private set currentPlace(val: Place) {
		console.log('### Setting currentPlace to ', val);
		this._currentPlace.next(val);
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
	refreshPlaces() {
		if (this.currentLocation) {
			const locationCoords = {
				coords: {
					latitude:  this.currentLocation.lat,
					longitude: this.currentLocation.lng
				}
			};
			this.loadPlacesByLocation(locationCoords);
		}
		// this.loadPlacesByCounty(this.currentCounty);
	}

	refreshPlace(placeId) {
		const place = this.places.find(x => x.id === placeId);
		if (place) {
			this.apiService.getPlaceById(placeId).subscribe(
				res => {
					if (res) {
						const newPlace = res as Place;
						const index = this.places.indexOf(place);
						this.places[index] = newPlace;
						// this.places[index] = {
						// 	...newPlace
						// };
						// this.places = [...this.places];
						this.setCurrentPlace(newPlace);
						this.refreshCustomer();
					}
				}
			);
		}
	}

	// get a place by id, but filter by group e_menu
	// to return products FIXME check this
	// fetchEMenuById(placeId) {
	// 	this.apiService.getEMenuById(placeId).subscribe(
	// 		newPlace => {
	// 			if (newPlace) {
	// 				this.currentPlace = newPlace as Place;
	// 			}
	// 		}
	// 	);
	// }

	refreshPlaceWithMenu(placeId) {
		const place = this.places.find(x => x.id === placeId);
		if (place) {
			this.apiService.getEMenuById(placeId).subscribe(
				res => {
					if (res) {
						const newPlace = res as Place;
						const index = this.places.indexOf(place);
						this.places[index] = newPlace;
						// this.places[index] = {
						// 	...newPlace
						// };
						// this.places = [...this.places];
						this.setCurrentPlace(newPlace);
						this.refreshCustomer();
					}
				}
			);
		}
	}

	setCurrentPlace(place: Place) {
		this.currentPlace = place;
	}

	getPlaceById(placeId, params = {}, refresh = false): Observable<any> {
		// const results = this.places.filter( x => x.id === placeId);
		// const place = results.length > 0 ? results[0] : null;
		const place = this.places.find( x => x.id === placeId);
		if (place && !refresh) {
			return of(place);
		} else {
			return this.apiService.getPlaceById(placeId, params);
		}
	}

	searchPlaces(searchTerm) {
		console.log('Dataservice: searchPlaces: ', searchTerm);
		const scope = this.places.map( x => x.id ).join(',');
		console.log('Dataservice scope: ', scope);
		this.apiService.searchPlaces(scope, searchTerm).subscribe(
			data => {
				if (data && data['hydra:member']) {
					this.searchResults = data['hydra:member'];
				}
			},
			error => {
				console.log('search error: ', error);
				this.errorService.showError(error);
			}
		);
	}

	loadPlacesByLocation(location) {
		console.log('DataService: loadPlacesByLocation: ', location);
		this.apiService.getPlacesByLocation(location).subscribe(
			data => {
				if (data && data['hydra:member']) {
					const places = data['hydra:member'];
					// enable themes from the resulting places
					// and disable all the others
					// 1. flatten list of places themes
					let placesThemes = places.flatMap(x => x.themes);
					// 2. make the array unique
					// unique array @see https://stackoverflow.com/a/9229821/2393822
					placesThemes = [...new Set(placesThemes)];
					// 3. for each theme, check if in array
					this.themes.forEach(
						theme => {
							theme.enabled = placesThemes.indexOf(theme['@id']) !== -1;
						}
					);
					// 4. sort themes in order to show the enabled ones first
					this.themes.sort((a, b) => (a.enabled && !b.enabled) ? -1 : ((b.enabled && !a.enabled) ? 1 : 0));

					// update the places
					this.places = places;
				}
			},
			error => {
				console.log('Could not load places.');
				console.log('DataService error: ', error);
				this.errorService.showError(error);
			}
		);
	}

	loadPlacesByCounty(county) {
		console.log('@@@ DataService: loadPlacesByCounty: ', county);
		this.apiService.getPlacesByCounty(county).subscribe(
			data => {
				// tslint:disable-next-line:no-string-literal
				if (data && data['places']) {
					// tslint:disable-next-line:no-string-literal
					const places = data['places'];
					// enable themes from the resulting places
					// and disable all the others
					// 1. flatten list of places themes
					let placesThemes = places.flatMap(x => x.themes);
					// 2. make the array unique
					// unique array @see https://stackoverflow.com/a/9229821/2393822
					placesThemes = [...new Set(placesThemes)];
					// 3. for each theme, check if in array
					this.themes.forEach(
						theme => {
							console.log('checking theme: ', theme);
							theme.enabled = placesThemes.indexOf(theme['@id']) !== -1;
						}
					);
					// 4. sort themes in order to show the enabled ones first
					this.themes.sort((a, b) => (a.enabled && !b.enabled) ? -1 : ((b.enabled && !a.enabled) ? 1 : 0));

					// update the places
					this.places = places;
				}
			},
			error => {
				console.log('Could not load places.');
				console.log('DataService error: ', error);
				this.errorService.showError(error);
			}
		);
	}

	/* ====================================================================== */
	/*   S L O T S
	/* ====================================================================== */
	private get slots(): Slot[] {
		return this._slots.getValue();
	}

	private set slots(val: Slot[]) {
		console.log('### Setting slots to ', val);
		this._slots.next(val);
	}

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
	public reserveSlot(place: Place, slot: Slot): Observable<boolean> {

		return new Observable((observer) => {

			const customer = this.customer;
			const places = this.places;

			this.apiService.reserveSlot(place, slot, customer).subscribe(
				newPlace => {
					console.log('reserveSlot result: ', newPlace);
					if (newPlace) {
						// we need to make a new copy of todos array, and the todo as well
						// remember, our state must always remain immutable
						// otherwise, on push change detection won't work, and won't update its view
						const index = this.places.indexOf(place);
						places[index] = newPlace as Place;
						this.places = [...places];
						// this.places[index] = {
						// 	...newPlace
						// };
						// this.places = [...this.places];
						this.refreshCustomer();
						observer.next(true);

					} else {
						observer.next(false);
					}

					observer.complete();

				},
				error => {
					console.log('DataService error: ', error);
					// this.errorService.showError(error);
					observer.error(error);
				}
			);

		});

	}

	public cancelSlot(place: Place, slot: Slot): Observable<boolean> {

		return new Observable((observer) => {

			const customer = this.customer;
			const places = this.places;

			this.apiService.cancelSlot(place, slot, customer).subscribe(
				newPlace => {
					console.log('cancelSlot result: ', newPlace);
					if (newPlace) {
						// we need to make a new copy of todos array, and the todo as well
						// remember, our state must always remain immutable
						// otherwise, on push change detection won't work, and won't update its view
						const index = this.places.indexOf(place);
						places[index] = newPlace as Place;
						this.places = [...places];
						// this.places[index] = newPlace as Place;
						// this.places[index] = {
						// 	...newPlace
						// };
						// this.places = [...this.places];
						this.refreshCustomer();
						observer.next(true);

					} else {
						observer.next(false);
					}

					observer.complete();

				},
				error => {
					console.log('DataService error: ', error);
					// this.errorService.showError(error);
					observer.error(error);

				}
			);

		});

	}

	// loadCurrentSlotsByPlace(placeId) {
	// 	console.log('DataService: loadCurrentSlotsByPlace: ', placeId);
	// 	this.apiService.getCurrentSlotsByPlace(placeId).subscribe(
	// 		data => {
	// 			if (data && data['hydra:member']) {
	// 				this.slots = data['hydra:member'];
	// 			}
	// 		},
	// 		error => {
	// 			console.log('DataService error: ', error);
	// 			this.errorService.showError(error);
	// 		}
	// 	);
	// }

	// getSlotsByPlace(placeId: number, page = 1): Observable<any> {
	//
	// 	const observable = new Observable((observer) => {
	//
	//
	// 		console.log('getSlotsByPlace: placeId: ', placeId);
	// 		const place = this.places.find( x => x.id === placeId);
	//
	// 		if (place) {
	//
	// 			this.apiService.getSlotsByPlace(placeId, page).subscribe(
	// 				data => {
	// 					console.log('getSlotsByPlace: data: ', data);
	// 					if (data && data['hydra:member']) {
	//
	// 						const slots = data['hydra:member'];
	// 						observer.next(slots);
	// 						observer.complete();
	//
	// 						// place.slots = slots;
	// 						//
	// 						// // we need to make a new copy of todos array, and the todo as well
	// 						// // remember, our state must always remain immutable
	// 						// // otherwise, on push change detection won't work, and won't update its view
	// 						// const index = this.places.indexOf(place);
	// 						// this.places[index] = {
	// 						// 	...place
	// 						// };
	// 						// this.places = [...this.places];
	//
	// 					}
	// 				},
	// 				error => {
	// 					console.log('getPlaceDetailsAndSlots: error: ', error);
	// 					observer.error(error);
	// 					// this.errorService.showError(error);
	// 				}
	// 			);
	//
	// 		} else {
	// 			observer.error('no_such_place'); // FIXME
	// 			observer.complete();
	// 		}
	//
	// 	});
	// 	return observable;
	// }

	/* ====================================================================== */
	/*   C A R T S
	/* ====================================================================== */
	// the getter will return the last value emitted in _cart subject
	private get cart(): Cart {
		return this._cart.getValue();
	}

	// assigning a value to this.cart will push it onto the observable
	// and down to all of its subscribers (ex: this.cart = null)
	private set cart(val: Cart) {
		console.log('### Setting cart to ', val);
		this._cart.next(val);
	}

	private createCart(place: Place, payWhenOrdering: boolean) {
		console.log('creating cart...');
		const cart = {
			currency: place.currency,
			status: 'open',
			customerOrders: [],
			payWhenOrdering
		};
		return this.apiService.createCart(cart).toPromise();
	}

	/**
	 * A cart is comprised of one or more orders.
	 * An order is specific to a single place.
	 * The place is inferred from the item being added to the cart.
	 */
	private lookupOrderByPlace(orders, place) {
		// return orders.filter(x => x.place['@id'] === place['@id']);
		return orders.filter(x => (x.place === place['@id'] || x.place['@id'] === place['@id']));
	}

	/**
	 * Assert two order items with the same product are the same
	 * when accounting for productOptions and variations
	 */
	private compareOrderItems(item1, item2) {
		if (item1.product['@id'] === item2.product['@id']) {

			console.log('compareOrderItems: productOptions1: ', item1.productOptions);
			console.log('compareOrderItems: productOptions2: ', item2.productOptions);

			// tslint:disable-next-line:max-line-length
			const options1 = item1.productOptions ? item1.productOptions.map( x => x['@id'].replace('/api/product_options/', '')).sort().join('|') : null;
			// tslint:disable-next-line:max-line-length
			const options2 = item2.productOptions ? item2.productOptions.map( x => x['@id'].replace('/api/product_options/', '')).sort().join('|') : null;

			// const variations1 = item1.variations ? item1.variations.map( x => x.id).sort() : null;
			// const variations2 = item2.variations ? item2.variations.map( x => x.id).sort() : null;

			const variation1 = item1.variation;
			const variation2 = item2.variation;

			console.log('compareOrderItems: options1: ', options1);
			console.log('compareOrderItems: options2: ', options2);
			console.log('compareOrderItems: variation1: ', variation1);
			console.log('compareOrderItems: variation2: ', variation2);
			if (options1 === options2 && variation1 === variation2) {
				return true;
			}
		}
		return false;
	}

	private lookupItemByProduct(items, product, compareItem) {
		// return items.filter(x => x.product['@id'] === product['@id']);
		let matchItem = null;
		const matchingItems = items.filter(x => x.product['@id'] === product['@id']);
		if (matchingItems && matchingItems.length > 0) {
			for (const match of matchingItems) {
				if (this.compareOrderItems(compareItem, match)) {
					matchItem = match;
					break;
				}
			}
		}
		return matchItem;
	}

	private updateOrCreateOrder(action, cart, order) {

		let orders = cart.customerOrders;

		const apiCall = action === 'create' ?
			this.apiService.createOrder(order) :
			this.apiService.updateOrder(order);

		apiCall.subscribe(
			response => {
				console.log('addToCart: order: ', response);
				if (response) {

					const newOrder = response as Order;

					const index = orders.findIndex(x => x['@id'] === newOrder['@id']);
					if (index > -1) {
						orders[index] = newOrder;
						// we need to make a new copy of todos array, and the todo as well
						// remember, our state must always remain immutable
						// otherwise, on push change detection won't work, and won't update its view
						orders = [...orders];
					} else {
						orders = [...orders, newOrder];
					}

					// update cart properties
					cart.customerOrders = orders;
					// tslint:disable-next-line:no-string-literal
					cart.itemCount  	= newOrder.cart['itemCount'];
					// tslint:disable-next-line:no-string-literal
					cart.totalAmount    = newOrder.cart['totalAmount'];

					this.cart = cart;

					this.refreshCustomer();

					// update cart's item count and total amount
					// this.cartItemCount   = resOrder['cart']['itemCount'];
					// this.cartTotalAmount = resOrder['cart']['totalAmount'];

				}
			},
			error => {
				console.log('addToCart: order: error: ', error);
				// observer.error(error);
			}
		);
	}
	async addToCart(item: OrderItem, place: Place, marketplace: Place) {

		console.log('addToCart: item: ', item);
		console.log('addToCart: place: ', place);

		let payWhenOrdering = false;
		const deliveryMethod = this.deliveryMethod;
		const deliverySeatCode = this.deliverySeatCode;

		if (DeliveryMethodOptions.dine_in !== deliveryMethod) {
			payWhenOrdering = true;
		}

		if (!this.cart) {
			this.cart = await this.createCart(place, payWhenOrdering);
			console.log('new cart: ', this.cart);
		}

		const cart = this.cart;
		console.log('this.cart: ', cart);
		// console.log('### JSON ### cart: ', JSON.stringify(cart));

		const orders = this.cart.customerOrders;
		// console.log('### JSON ### customer orders: ', JSON.stringify(orders));

		const product     = item.product;
		// const place       = product['place'] ? product['place'] : null;
		// const product_iri = product['@id'] ? product['@id'] : product;

		console.log('addToCart: place: ', place);

		if (!place) {
			console.log('error: no place');
			return false; // FIXME
		}

		let matchingOrders = [];
		let order = null;
		// let apiCall = null;
		let newOrder = null;

		// check if the cart already has an order for this place
		if (orders.length > 0) {
			console.log('looking for orders matching place: ', place);
			matchingOrders = this.lookupOrderByPlace(orders, place); // orders.filter(x => x.place['@id'] === place['@id']);
			console.log(matchingOrders);
		}

		// console.log('### JSON ### matching orders: ', JSON.stringify(matchingOrders));

		// if an order already exists, add the item
		if (matchingOrders && matchingOrders.length > 0) {

			order = matchingOrders.shift(); // in doubt, get first order
			console.log('addToCart: add item to existing order: ', order);
			console.log('looking for item in order items');
			console.log('item: ', item);
			console.log('order items: ', order.items);

			// console.log('### JSON ### order.items: ', JSON.stringify(order.items));

			// observer.error('debug'); return false;
			// FIXME need to compare options
			const currItem = this.lookupItemByProduct(order.items, product, item);
			// const matchingItems = this.lookupItemByProduct(order.items, product);
			// console.log('matchingItems: ', matchingItems);
			// if (matchingItems && matchingItems.length > 0) {
			// 	for (const match of matchingItems) {
			// 		if (this.compareOrderItems(item, match)) {
			// 			currItem = match;
			// 			break;
			// 		}
			// 	}
			// }
			if (currItem) {
				console.log('match FOUND: ', currItem);
				currItem.quantity += item.quantity;
			} else {
				console.log('match NOT found: ', product);
				// order.items = [...order.items, item];
				order.items.push(item);
			}

			// apiCall = this.apiService.updateOrder(order);
			this.updateOrCreateOrder('update', cart, order);


		// otherwise create a new order with the item
		} else {

			newOrder = {
				cart: cart['@id'],
				marketplace,
				place,
				currency: place.currency,
				items: [item],
				totalAmount: 0,
				paidAmount: 0,
				deliveryMethod,
				deliverySeatCode
			} as Order;

			console.log('addToCart: creating new order: ', newOrder);
			// apiCall = this.apiService.createOrder(newOrder);
			this.updateOrCreateOrder('create', cart, newOrder);

		}

	}

	async removeFromCart(item: OrderItem, place: Place) {

		console.log('removeFromCart: item: ', item);
		const cart    = this.cart;
		const orders  = cart.customerOrders;
		const product = item.product;
		// const place   = product.place;

		// lookup order by place
		const matchingOrders = this.lookupOrderByPlace(orders, place);
		if (matchingOrders && matchingOrders.length > 0) {

			const order = matchingOrders.shift(); // in doubt, get first order
			const currItem = this.lookupItemByProduct(order.items, product, item);
			if (currItem) {
				currItem.quantity -= item.quantity;
				this.updateOrCreateOrder('update', cart, order);
			} else {
				// FIXME shouldn't happen
				console.log('### error: removeFromCart: item not found: ', item);
			}
			// const matchingItems = this.lookupItemByProduct(order.items, product); // order.items.filter(x => x.product['@id'] === product['@id']);
			// if (matchingItems  && matchingItems.length > 0) {
			// 	const currItem = matchingItems.shift();
			// 	currItem.quantity -= item.quantity;
			// 	this.updateOrCreateOrder('update', cart, order);
			// }

		}

	}

	/**
	 * Returned cart statuses:
	 *     - received: payment was sucessful and all orders were received
	 *     - rejected: payment was rejected, check paymentError
	 */
	public placeAllCartOrders(paymentMethod, paymentMethodId) {

		return new Observable((observer) => {

			const cart = this.cart;
			cart.paymentMethod = paymentMethod;
			cart.paymentMethodId = paymentMethodId;
			this.apiService.placeAllCartOrders(this.cart).subscribe(
				newCart => {
					console.log('placeAllCartOrders: newCart: ', newCart);
					if (newCart) {

						const status = newCart.status;

						// payment was accepted and cart received for processing
						if (status === 'received') {

							// cart.status = newCart.status;
							// this.cart = cart;

							// clear the current cart
							this.cart = null;
							this.refreshCustomer();
							observer.next(true);

						// cart was rejected, check payment
						} else {

							console.log('cart was rejected, check payment: ', newCart);

							cart.status        = newCart.status;
							cart.stripeClientSecret = newCart.stripeClientSecret;
							cart.paymentToken  = newCart.paymentToken;
							cart.paymentStatus = newCart.paymentStatus;
							cart.paymentError  = newCart.paymentError;

							this.cart = cart;
							observer.next(false);

						}

					} else {
						observer.next(false);
					}

					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					this.errorService.showError(error);
					observer.error(error);
				}
			);

		});

	}

	public getCustomerRequestsByPlace(placeId: number): Observable<CustomerRequests> {
		return new Observable((observer) => {
			this.apiService.getCustomerRequestsByPlace(placeId).subscribe(
				data => {
					if (data && data['hydra:member'] && data['hydra:member'].length > 0) {
						const customerRequests = data['hydra:member'][0] as CustomerRequests;
						observer.next(customerRequests);
					} else {
						observer.next(null);
					}
					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					this.errorService.showError(error);
					observer.error(error);
				}
			);
		});
	}

	/* ====================================================================== */
	/*   D E L I V E R Y   M E T H O D
	/* ====================================================================== */
	private get deliveryMethod(): string {
		return this._deliveryMethod.getValue();
	}

	private set deliveryMethod(val: string) {
		console.log('### Setting deliveryMethod to ', val);
		this._deliveryMethod.next(val);
	}

	private get deliverySeatCode(): string {
		return this._deliverySeatCode.getValue();
	}

	private set deliverySeatCode(val: string) {
		console.log('### Setting deliverySeatCode to ', val);
		this._deliverySeatCode.next(val);
	}


	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
	public setDeliveryMethod(deliveryMethod, seatCode): Observable<boolean> {
		return new Observable((observer) => {
			this.deliveryMethod = deliveryMethod;
			this.deliverySeatCode = seatCode; // FIXME validate
			observer.next(true);
			observer.complete();
		});
	}


	/* ====================================================================== */
	/*   P R O D U C T S
	/* ====================================================================== */
	public getProductDetails(product: Product): Observable<Product> {
		return this.apiService.getProductDetails(product);
	}

	/* ====================================================================== */
	/*   N O T I F I C A T I O N S
	/* ====================================================================== */
	private get notifications(): Notification[] {
		return this._notifications.getValue();
	}

	private set notifications(val: Notification[]) {
		// console.log('### Setting notifications to ' + JSON.stringify(val));
		this._notifications.next(val);
	}

	private get unreadNotifications(): number {
		return this._unreadNotifications.getValue();
	}

	private set unreadNotifications(val: number) {
		// console.log('### Setting unreadNotifications to ' + JSON.stringify(val));
		this._unreadNotifications.next(val);
	}

	private get totalUnreadNotifications(): number {
		return this._totalUnreadNotifications.getValue();
	}

	private set totalUnreadNotifications(val: number) {
		// console.log('### Setting totalUnreadNotifications to ' + JSON.stringify(val));
		this._totalUnreadNotifications.next(val);
	}

	public refreshNotifications() {
		console.log('refreshNotifications: user: ', this.user);
		if (this.user && this.user.id) {
			const userid = this.user.id;
			this.apiService.getUserNotifications(userid).subscribe(
				data => {
					console.log('refreshNotifications: data: ', data);
					if (data && data['hydra:member']) {
						let unreadNotifications = 0;
						const notifications = data['hydra:member'][0];
						if (notifications && notifications.length > 0) {
							notifications.forEach( notification => {
								if (!notification.readAt) {
									unreadNotifications++;
								}
							});
						}
						this.notifications = notifications;
						this.unreadNotifications = unreadNotifications;
						this.totalUnreadNotifications = data['hydra:member'][1];
					}
				},
				error => {
					console.log('Could not load notifications.');
					console.log('DataService error: ', error);
					this.errorService.showError(error);
				}
			);
		}
	}

	public addNotification(notificationEnvelope: NotificationEnvelope) {
		console.log('DataService: addNotification: ', notificationEnvelope);
		this.refreshCustomer();
		this.refreshNotifications();
	}

	public markAllNotificationsAsRead() {
		this.notifications.forEach(
			notification => {
				this.markNotificationAsRead(notification);
			}
		);
	}

	public markNotificationAsRead(notification) {
		this.apiService.markNotificationAsRead(notification).subscribe(
			data => {
				console.log('notification updated: ', data);
				if (data) {
					const modNotification = data as Notification;
					const index = this.notifications.indexOf(modNotification);
					this.notifications[index] = {
						...modNotification
					};
					this.notifications = [...this.notifications];
					this.unreadNotifications--;
					this.totalUnreadNotifications--;
				}
			},
			error => {
				console.log('Error marking notification as read');
				this.errorService.showError(error);
			}
		);
	}

	/* ====================================================================== */
	/*   R E S E R V A T I O N S
	/* ====================================================================== */
	// private get reservations(): GroupReservation[] {
	// 	return this._reservations.getValue();
	// }
	//
	// private set reservations(val: GroupReservation[]) {
	// 	console.log('### Setting reservations to ' + JSON.stringify(val));
	// 	this._reservations.next(val);
	// }
	//

	/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
	public newReservation(customer, place, dateAndTime, numPax, comments): GroupReservation {
		const reservation = {
			id: null,
			customer,
			place,
			dateAndTime,
			numPax,
			comments,
			status: 'incomplete'
		} as GroupReservation;
		return reservation;
	}

	public makeReservation(reservation: GroupReservation): Observable<boolean> {
		return new Observable((observer) => {
			this.apiService.makeReservation(reservation).subscribe(
				newReservation => {
					console.log('makeReservation result: ', newReservation);
					if (newReservation) {
						this.refreshCustomer();
						observer.next(true);
					} else {
						observer.next(false);
					}
					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					observer.error(error);
				}
			);
		});
	}

	public cancelReservation(reservation: GroupReservation): Observable<boolean> {
		return new Observable((observer) => {
			this.apiService.cancelReservation(reservation).subscribe(
				newReservation => {
					console.log('cancelReservation result: ', newReservation);
					if (newReservation) {
						this.refreshCustomer();
						observer.next(true);
					} else {
						observer.next(false);
					}
					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					observer.error(error);
				}
			);
		});
	}

	// public refreshReservations() {
	// 	console.log('refreshReservations: customer: ', this.customer);
	// 	if (this.customer && this.customer.id) {
	// 		this.apiService.getUserReservations(this.customer).subscribe(
	// 			data => {
	// 				if (data && data['hydra:member']) {
	// 					this.reservations = data['hydra:member'];
	// 				}
	// 			},
	// 			error => {
	// 				console.log('Could not load reservations.');
	// 				console.log('DataService error: ', error);
	// 				this.errorService.showError(error);
	// 			}
	// 		);
	// 	}
	// }

	public getCustomerReservationsByPlace(placeId: number): Observable<CustomerReservations> {
		return new Observable((observer) => {
			this.apiService.getCustomerReservationsByPlace(placeId).subscribe(
				data => {
					if (data && data['hydra:member'] && data['hydra:member'].length > 0) {
						const customerReservations = data['hydra:member'][0] as CustomerReservations;
						observer.next(customerReservations);
					} else {
						observer.next(null);
					}
					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					this.errorService.showError(error);
					observer.error(error);
				}
			);
		});
	}

	/* ====================================================================== */
	/*   B I L L S  /  P A Y M E N T S
	/* ====================================================================== */
	public makePayment(bill: Bill, paymentMethod: string, paymentMethodId: string): Observable<Checkout> {
		return new Observable((observer) => {
			// const payment = {
			// 	type: 'in_app',
			// 	currency: bill.currency,
			// 	amount: bill.finalAmount, // PAID IN FULL for now
			// 	paymentMethod,
			// 	paymentMethodId
			// } as Payment;

			this.apiService.getCheckoutById(bill.id).subscribe(
				checkout => {

					if (checkout) {

						this.apiService.generatePaymentIntent(checkout as Checkout, paymentMethod, paymentMethodId).subscribe(
							modCheckout => {
								console.log('makePayment result: ', modCheckout);
								if (modCheckout) {
									this.refreshCustomer();
									observer.next(modCheckout as Checkout);
								} else {
									observer.next(null);
								}
								observer.complete();
							},
							error => {
								console.log('DataService error: ', error);
								observer.error(error);
							}
						);


					}

				}
			);


		});
	}

	public checkBillPaymentStatus(bill: Bill, payment: Payment): Observable<any> {

		console.log('checkPaymentStatus');

		return new Observable((observer) => {

			this.apiService.getCheckoutById(bill.id).subscribe(
				checkout => {

					if (checkout) {

						this.apiService.checkBillPaymentStatus(checkout as Checkout, payment.id).subscribe(
							modCheckout => {
								console.log('checkPaymentStatus result: ', modCheckout);
								if (modCheckout) {
									this.refreshCustomer();
									observer.next(modCheckout as Checkout);
								} else {
									observer.next(null);
								}
								observer.complete();
							},
							error => {
								console.log('DataService error: ', error);
								observer.error(error);
							}
						);

					}

				}
			);

		});

	}

	public checkCartPaymentStatus(paymentToken): Observable<any> {

		console.log('checkPaymentStatus: ', paymentToken);
		const cart = this.cart;
		cart.paymentToken = paymentToken;

		return new Observable((observer) => {

			this.apiService.checkCartPaymentStatus(cart, paymentToken).subscribe(
				newCart => {
					console.log('checkPaymentStatus: newCart: ', newCart);
					if (newCart) {

						const status = newCart.status;

						// payment was accepted and cart received for processing
						if (status === 'received') {

							// clear the current cart
							this.cart = null;
							observer.next(true);

						// cart was rejected, check payment
						} else {

							cart.status        = newCart.status;
							cart.stripeClientSecret = newCart.stripeClientSecret;
							cart.paymentToken  = newCart.paymentToken;
							cart.paymentStatus = newCart.paymentStatus;
							cart.paymentError  = newCart.paymentError;

							this.cart = cart;
							observer.next(false);

						}

					} else {
						observer.next(false);
					}

					observer.complete();
				},
				error => {
					console.log('DataService error: ', error);
					this.errorService.showError(error);
					observer.error(error);
				}
			);

		});

	}

	getLoadedBillById(billId) {
		if (this.customer && this.customer.currentBills) {
			return this.customer.currentBills.find(x => x.id === billId);
		}
		return null;
	}
}
