import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuController, ModalController, AlertController } from '@ionic/angular';
import { ConfigurationService } from '@app/_services/configuration.service';

import { first } from 'rxjs/operators';

import { AuthenticationService } from '@app/_services/authentication.service';
import { ApiService } from '@app/_services/api.service';
import { ErrorService } from '@app/_services/error.service';

import { ForgotPasswordPage } from '../forgot-password/forgot-password.page';
import { SignupPage } from '../signup/signup.page';
import { MycascaisLoginPage } from '../mycascais-login/mycascais-login.page';
import { WebIntent } from '@ionic-native/web-intent/ngx';

@Component({
	selector: 'app-login',
	templateUrl: './login.page.html',
	styleUrls: [
		'./login.page.scss'
	]
})

export class LoginPage implements OnInit {

	// Data passed in by componentProps or route snapshot
	@Input() isModal: boolean;

	loginForm: FormGroup;
	processing = false;
	returnUrl: string;
	error = '';
	version: string;

	validationMessages = {
		email: [
			{ type: 'required', message: 'validation.email.required' },
			{ type: 'pattern', message: 'validation.email.pattern' }
		],
		password: [
			{ type: 'required', message: 'validation.password.required' },
			{ type: 'minlength', message: 'validation.password.minlength' }
		]
	};

	// FIXME DEBUG
	credentials = {
		username: '', // 'miles@golkee.com',
		password: '', // 'miles'
	};

	loggedIn = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private menu: MenuController,
		private modalController: ModalController,
		public alertController: AlertController,
		private authenticationService: AuthenticationService,
		private configurationService: ConfigurationService,
		private apiService: ApiService,
		private errorService: ErrorService,
		private webIntent: WebIntent
	) {

		console.log('Hello LoginPage');

		// redirect to home if already logged in
		// if (this.authenticationService.currentUserValue) {
		// 	this.router.navigate(['/app/welcome']);
		// }

		this.loginForm = new FormGroup({
			email: new FormControl(this.credentials.username, Validators.compose([
				Validators.required,
				Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
			])),
			password: new FormControl(this.credentials.password, Validators.compose([
				Validators.minLength(5),
				Validators.required
			]))
		});
	}

	ngOnInit(): void {
		// this.menu.enable(false);
		// get return url from route parameters or default to '/'
		this.version = this.configurationService.getVersion();
		this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/app/welcome';
		this.isModal = this.isModal ? this.isModal : this.route.snapshot.queryParams.modal;
	}

	ionViewDidEnter() {
		this.loggedIn = false;
		this.processing = false;
	}

	doLogin(): void {
		console.log('do Log In');

		this.credentials.username = this.loginForm.get('email').value;
		this.credentials.password = this.loginForm.get('password').value;

		console.log(this.credentials);

		this.processing = true;
		this.authenticationService.login(this.credentials.username, this.credentials.password)
		.pipe(first())
		.subscribe(
			data => {
				// setting this.loggedIn to true
				// will display a success message
				// see login.page.html
				this.loggedIn = true;
				// if (this.isModal) {
				// 	// this.modalController.dismiss(true);
				// } else {
				// 	// this.router.navigate([this.returnUrl]);
				// }
			},
			error => {
				if (error && error.error && error.error.message && error.error.message === 'account_not_verified') {
					this.presentResendVerificationPropmpt();
				} else {
					this.error = error;
					this.processing = false;
					this.errorService.showError(error.error); // FIXME
				}
				// if (this.isModal) {
				// this.dismiss();
					// this.modalController.dismiss(false);
				// }
			});
			// data => {
			// 	this.router.navigate([this.returnUrl]);
			// },
			// error => {
			// 	this.error = error;
			// 	this.processing = false;
			// 	this.errorService.showError({message: 'bad_credentials'}); // FIXME
			// });

		}

		dismiss() {
			if (this.isModal) {
				this.modalController.dismiss(this.loggedIn);
			} else {
				this.router.navigate([this.returnUrl]);
			}
		}

		async goToForgotPassword() {
			if (this.isModal) {
				await this.presentForgotPasswordModal();
			} else {
				await this.router.navigate(['/auth/forgot-password']);
			}
		}

		async presentForgotPasswordModal() {
			const modal = await this.modalController.create({
				component: ForgotPasswordPage,
				cssClass: 'login-modal',
				componentProps: {
					isModal: true
				}
			});
			modal.present();
			const { data } = await modal.onWillDismiss();
			return data;
		}

		/**
		 * FIXME not working
		 * @see https://github.com/tavosansal/capacitor-plugin-send-intent
		 * @see https://developer.android.com/guide/components/intents-filters
		 * @see https://ionicframework.com/docs/native/web-intent
		 */
		doWebIntent() {
			const options = {
			  action: this.webIntent.ACTION_VIEW,
			  url: 'nsop.neds.mycascais',
			  type: 'application/vnd.android.package-archive'
		  };

			this.webIntent.startActivity(options)
				.then(res => {
					console.log('webIntent response: ', res);
				})
				.catch(err => {
					console.log('webIntent error: ', err);
				});
		}

		startActivityExplicit() {
				// tslint:disable-next-line
		        window['plugins'].intentShim.startActivity(
		            {
		                component:
		                {
		                    package: 'nsop.neds.mycascais',
		                    class: 'nsop.neds.mycascais.LoginActivity'
		                },
		                extras:
		                {
							packagename: 'com.golkee.app',
							appid: '23'
		                }
		            },
		            (intent) => {
		                // document.getElementById('startActivityResultData').innerHTML = 'StartActivity success';
						console.log('StartActivity success: ' + JSON.stringify(intent));
		            },
		            () => {
		                // document.getElementById('startActivityResultData').innerHTML = 'StartActivity failure';
						console.log('StartActivity failure');
		            }
		        );

		}

		async goToMyCascais() {
			if (this.isModal) {
				await this.presentMyCascaisModal();
			} else {
				await this.router.navigate(['/auth/mycascais-login']);
			}
		}

		async presentMyCascaisModal() {
			const modal = await this.modalController.create({
				component: MycascaisLoginPage,
				cssClass: 'modal login-modal',
				componentProps: {
					isModal: true
				}
			});
			modal.present();
			const { data } = await modal.onWillDismiss();
			return data;
		}

		async goToSignup() {
			if (this.isModal) {
				await this.presentSignupModal();
			} else {
				await this.router.navigate(['/auth/signup']);
			}
		}

		async presentSignupModal() {
			const modal = await this.modalController.create({
				component: SignupPage,
				cssClass: 'modal login-modal',
				componentProps: {
					isModal: true
				}
			});
			modal.present();
			const { data } = await modal.onWillDismiss();
			return data;
		}

		resendVerificationEmail(): void {
			this.processing = true;
			const params = {
				email: this.loginForm.get('email').value
			};
			console.log(params);
			this.apiService.resendVerificationEmail(params)
			.subscribe(data => {
				// execution never reaches here in development mode
				// because an "Http failure during parsing" error will be thrown
				// due to the response having no content
				// this error is intercepted below
				console.log('resendVerificationEmail request accepted');
				this.presentSuccessAlert();
				this.processing = false;
			},
			error => {
				console.log('resendVerificationEmail error:', error);
				if (error.status === 202) {
					// no error, accepted
					console.log('resendVerificationEmail request accepted');
					this.presentSuccessAlert();
					this.processing = false;
				} else {
					this.error = error;
					this.processing = false;
					this.errorService.showError(error);
				}
			});
		}

		// FIXME translations
		async presentResendVerificationPropmpt() {

			let header, message, oktext;
			header = 'A sua conta ainda não foi verificada';
			message = 'Clique em enviar para receber no seu email um novo link de verificação';
			oktext = 'Enviar';

			const alert = await this.alertController.create({
				cssClass: 'my-custom-class',
				header,
				message,
				buttons: [
					{
						text: 'Cancelar',
						role: 'cancel',
						cssClass: 'secondary',
						handler: (blah) => {
							console.log('Confirm Cancel: blah');
						}
					}, {
						text: oktext,
						handler: () => {
							this.resendVerificationEmail();
						}
					}
				]
			});

			await alert.present();
		}

		// FIXME translations
		async presentSuccessAlert() {

			let header, message, oktext;
			header = 'Pedido de reenvio de email de verificação efectuado com sucesso';
			message = 'Verifique o seu email e siga as instruções';
			oktext = 'OK';

			const alert = await this.alertController.create({
				cssClass: 'my-custom-class',
				header,
				message,
				buttons: [
	 				{
						text: oktext,
						handler: () => {
							// this.dismiss();
						}
					}
				]
			});

			await alert.present();
		}

		async explore() {
			if (this.isModal) {
				this.dismiss();
			} else {
				await this.router.navigate(['/app/welcome']);
			}
		}

		doFacebookLogin(): void {
			console.log('facebook login');
			this.router.navigate(['/app/welcome']);
		}

		doGoogleLogin(): void {
			console.log('google login');
			this.router.navigate(['/app/welcome']);
		}

		doTwitterLogin(): void {
			console.log('twitter login');
			this.router.navigate(['/app/welcome']);
		}
	}
