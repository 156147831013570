import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalController, MenuController } from '@ionic/angular';
import { ConfigurationService } from '@app/_services/configuration.service';

// import { TermsOfServicePage } from '@app/terms-of-service/terms-of-service.page';
// import { PrivacyPolicyPage } from '@app/privacy-policy/privacy-policy.page';
import { PasswordValidator } from '@app/_validators/password.validator';
import { EmailValidator } from '@app/_validators/email.validator';

import { ApiService } from '@app/_services/api.service';
import { ErrorService } from '@app/_services/error.service';

import { AuthenticationService } from '@app/_services/authentication.service';

import { User } from '@app/_models/user.model';

import { Plugins, AppState } from '@capacitor/core';
const { Browser } = Plugins;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.page.html',
	styleUrls: [
		'./signup.page.scss'
	]
})
export class SignupPage implements OnInit {

	// Data passed in by componentProps or route snapshot
	@Input() isModal: boolean;

	processing = false;
	signupForm: FormGroup;
	matchingPasswordsGroup: FormGroup;
	version: string;

	user: User;

	validationMessages = {
		name: [
			{ type: 'required', message: 'validation.name.required' },
			{ type: 'pattern', message: 'validation.name.pattern' }
		],
		username: [
			{ type: 'required', message: 'validation.username.required' },
			{ type: 'pattern', message: 'validation.username.pattern' }
		],
		email: [
			{ type: 'required', message: 'validation.email.required' },
			{ type: 'pattern', message: 'validation.email.pattern' },
			{ type: 'emailInUse', message: 'validation.email.emailInUse' },
			{ type: 'unknown_error', message: 'validation.email.unknown_error' }
		],
		password: [
			{ type: 'required', message: 'validation.password.required' },
			{ type: 'minlength', message: 'validation.password.minlength' },
			{ type: 'passwordStrength', message: 'validation.password.passwordStrength' },
		],
		confirm_password: [
			{ type: 'required', message: 'validation.confirm_password.required' }
		],
		matching_passwords: [
			{ type: 'areNotEqual', message: 'validation.matching_passwords.areNotEqual' }
		]
	};

	signedUp = false;

	termsLink = 'https://golkee.com/terms';
	privacyLink = 'https://golkee.com/privacy';

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		public modalController: ModalController,
		public menu: MenuController,
		public api: ApiService,
		public errorService: ErrorService,
		public authenticationService: AuthenticationService,
		private configurationService: ConfigurationService,
		public emailValidator: EmailValidator
	) {

		this.user = {
			name: '',
			plainPassword: '',
			confirmPassword: '',
			email: '',
			username: ''
		} as User;

		this.matchingPasswordsGroup = new FormGroup({
			password: new FormControl(this.user.plainPassword, Validators.compose([
				Validators.minLength(6),
				Validators.required,
				PasswordValidator.passwordStrength
			])),
			confirm_password: new FormControl(this.user.confirmPassword, Validators.required)
		}, (formGroup: FormGroup) => {
			return PasswordValidator.areNotEqual(formGroup);
		});

		this.signupForm = new FormGroup({

			name: new FormControl (
				this.user.name,
				[Validators.required]
			),

			email: new FormControl(
				this.user.email,
				{
					validators: [
				   		Validators.required,
				   		Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
					],
					asyncValidators: [emailValidator.emailInUse.bind(emailValidator)],
					updateOn: 'blur'
				}
			),

			matching_passwords: this.matchingPasswordsGroup

		});

	}

	ngOnInit(): void {
		this.menu.enable(false);
		this.isModal = this.isModal ? this.isModal : this.route.snapshot.queryParams.modal;
		this.version = this.configurationService.getVersion();
	}

	ionViewDidEnter() {
		this.signedUp = false;
		this.processing = false;
	}

	async showTermsModal() {
		await Browser.open({ url: this.termsLink });
		//
		// const modal = await this.modalController.create({
		// 	component: TermsOfServicePage
		// });
		// return await modal.present();
	}

	async showPrivacyModal() {
		await Browser.open({ url: this.privacyLink });
		// const modal = await this.modalController.create({
		// 	component: PrivacyPolicyPage
		// });
		// return await modal.present();
	}

	doSignup(): void {
		console.log('do sign up');
		this.processing = true;

		this.user.name = this.signupForm.get('name').value;
		this.user.email = this.signupForm.get('email').value;
		this.user.plainPassword = this.signupForm.get('matching_passwords').get('password').value;

		this.user.username = this.user.email; // FIXME really?
		this.api.register(this.user).subscribe(

			(res) => {
				console.log(res);

				if (res) {
					// setting this.signedUp to true
					// will display a success message
					// see signup.page.html
					this.signedUp = true;

					// auto login FIXME no verification?
					// this.authenticationService.login(this.user.username, this.user.plainPassword).subscribe(allowed => {
					// 	console.log('login result: ', allowed);
					// 	if (allowed) {
					// 		this.router.navigate(['/app/welcome']);
					// 	} else {
					// 		this.errorService.showError({message: 'bad_credentials'});
					// 	}
					// });

				} else {
					this.errorService.showError({message: 'unknown_error'});
				}
				this.processing = false;
			},
			(error) => {
				console.log('registration error: ', error);
				this.errorService.showError(error);
				this.processing = false;
			}
		);
	}

	dismiss() {
		if (this.isModal) {
			this.modalController.dismiss();
		} else {
			this.router.navigate(['/auth/login']);
		}
	}

	async goToLogin() {
		this.dismiss();
	}

	// TODO
	doFacebookSignup(): void {
		console.log('facebook signup');
		this.router.navigate(['/app/welcome']);
	}

	doGoogleSignup(): void {
		console.log('google signup');
		this.router.navigate(['/app/welcome']);
	}

	doTwitterSignup(): void {
		console.log('twitter signup');
		this.router.navigate(['/app/welcome']);
	}
}
