import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
	name: 'firstName'
})
export class FirstNamePipe implements PipeTransform {

	transform(value: any): any {
		const names = value.split(/\s+/);
		console.log('names: ', names);
		if (names && names.length > 0) {
			return names[0];
		}
		return value;
	}

}
