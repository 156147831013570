/**
 * Capacitor Storage Service
 * @see https://www.joshmorony.com/using-the-capacitor-storage-api-for-storing-data/
 */
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

export async function storageSet(key: string, value: any): Promise<void> {
	await Storage.set({
		key,
		value: JSON.stringify(value)
	});
}

export async function storageGet(key: string): Promise<any> {
	const item = await Storage.get({ key });
	return JSON.parse(item.value);
}

export async function storageRemove(key: string): Promise<void> {
	await Storage.remove({
		key
	});
}
