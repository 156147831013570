import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { Platform, LoadingController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

// added
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { LoaderService } from '@app/_services/loader.service';
// import { SwUpdate } from '@angular/service-worker';
import { ApiService } from '@app/_services/api.service';
import { AuthenticationService } from '@app/_services/authentication.service';
import { NotificationService } from '@app/_services/notification.service';
import { ErrorService } from '@app/_services/error.service';

import { first } from 'rxjs/operators';

import { User } from './_models/user.model';

import { Plugins } from '@capacitor/core';
const { App } = Plugins;
const { CapacitorFirebaseDynamicLinks } = Plugins;

import { storageGet, storageSet, storageRemove } from '@app/_services/storage.service';

// import 'mycascais-plugin';
const { MyCascais } = Plugins;

// const { SendIntent } = Plugins;

// import { registerWebPlugin } from '@capacitor/core';
// import { Plugins } from '@capacitor/core';
// import 'mycascais-plugin';
// const { MyCascaisPlugin } = Plugins;

// @see: https://blog.angular-university.io/debug-rxjs/
// import { Observable } from 'rxjs';
// import { tap } from 'rxjs/operators';
//
// Observable.prototype.debug = function(message: string) {
// 	return this
// 	.pipe(
// 	    tap({
// 	      next: val => {
// 	        // on next 11, etc.
// 	        console.log('Observable debug: on next', val);
// 	      },
// 	      error: error => {
// 	        console.log('Observable debug: on error', error.message);
// 	      },
// 	      complete: () => console.log('Observable debug: on complete')
// 	    })
// 	);
//
// };

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss']
})
export class AppComponent {

	loadingMessage = 'loading';
	loadingElement: any = null;
	showingLoader = false;
	user: User;

	constructor(
		private platform: Platform,
		private splashScreen: SplashScreen,
		private statusBar: StatusBar,
		// added
		private router: Router,
		private translate: TranslateService,
		private modalController: ModalController,
		private loadingController: LoadingController,
		private loaderService: LoaderService,
		private apiService: ApiService,
		private authenticationService: AuthenticationService,
		private notificationService: NotificationService,
		private errorService: ErrorService,
		// private swUpdate: SwUpdate
		private zone: NgZone
	) {
		console.log('Hello AppComponent');
		// console.log('SendIntent: ', SendIntent);

		// SendIntent.addListener('appSendActionIntent', (data) => {
		// 	console.log('SendIntent: data: ', data);
		// 	const { extras } = data;
		// 	const textKey = 'android.intent.extra.TEXT';
		// 	alert(`This ${extras[textKey]} was shared from another app!`);
		// });

		this.initializeApp();
	}

	initializeApp() {

		this.platform.ready().then(() => {
			this.statusBar.styleDefault();
			this.splashScreen.hide();
			// added
			// this language will be used as a fallback when a translation isn't found in the current language
			this.translate.setDefaultLang('pt');
			// the lang to use, if the lang isn't available, it will use the current loader to get them
			this.translate.use('pt');

			this.translate.get(this.loadingMessage).subscribe(
				value => {
					this.loadingMessage = value;
					this.loadingController.create({
						message: this.loadingMessage,
						duration: 30000 // max 30 secs
					}).then( (res) => {
						this.loadingElement = res;
					});
				});

			this.loaderService.loading$.subscribe(
				loading => {
					if (loading) {
						this.presentLoader();
					} else {
						this.dismissLoader();
					}
				}
			);

			this.authenticationService.currentUser.subscribe(
				user => {
					// console.log('### USER: ', user);
					console.log('DEEP_LINKS: user: ', user);

					if (user) {
						this.user = user;
					} else {
						// this.invalidateStore();
						console.log('AppComponent: empty user, logged out');
						if (this.user) {
							this.user = null;
							// this.translate.get('logged_out').subscribe(
							// 	value => {
							// 		console.log('AppComponent: logged_out translations: ', value);
							// 		// this.showLoggedOutModal();
							// 	}
							// );
						}
					}
				}
			);

			// registerWebPlugin(MyCascaisPlugin);

			// @see https://capacitorjs.com/docs/guides/deep-links
			App.addListener('appUrlOpen', (data: any) => {
				this.zone.run(() => {
					// Example url: https://golkee.app/app/restaurants
					// slug = /app/restaurants
					// alert('appUrlOpen: ' + data.url);
					const slug = data.url.split('.app').pop();
					if (slug) {
						this.router.navigateByUrl(slug);
					}
					// If no match, do nothing - let regular routing
					// logic take over
				});
			});

			CapacitorFirebaseDynamicLinks.addListener('deepLinkOpen', (data: { url: string }) => {
      			// Implement your navigation handler
				// Example url: https://golkee.app/app/restaurants
				// slug = /app/restaurants
				alert('deepLinkOpen: ' + data.url);
				this.processDeepLink(data.url);

				// const slug = data.url.split('.app').pop();
				// if (slug) {
				// 	this.router.navigateByUrl(slug);
				// }
				// If no match, do nothing - let regular routing
				// logic take over
    		});

			// debug dynamic links
			// const link = 'https://golkee.app/app/restaurants/37?c=E2A37A';
			// this.processDeepLink(link);
			// const myCascaisResponse = MyCascais.checkResponse();
			// console.log('MyCascais checkResponse: ' + JSON.stringify(myCascaisResponse));


			// {
			//  	"AuthID":"81cbd679-6119-4096-b6a6-28c0d30418b4",
			//  	"DisplayName":"Golkee Tests",
			// 		"Fields":[],
			//  	"MyCascaisID":"123f6053-3e9b-4fca-a52a-c03bca985adc",
			//  	"SSK":"7372f703-6d20-47f1-af31-31ae54fd78c2",
			//  	"SessionExpirationDate":"2021-01-25T19:34:53.463"
			// }
			MyCascais.getMyCascaisLogin().then( data => {
				console.log('MyCascais Login: ' + JSON.stringify(data));
				if (data && data.result) {
					this.checkMyCascaisLogin(data.result);
				}
			});

		});

	}

	async processDeepLink(link) {
		const url = new URL(link);
		const slug = url.pathname;
		const searchParams = url.searchParams;
		const promocode = searchParams.get('c');
		console.log('DEEP_LINKS: url: ', url);
		console.log('DEEP_LINKS: slug: ', slug);
		console.log('DEEP_LINKS: searchParams: ', searchParams);
		console.log('DEEP_LINKS: promocode: ', promocode);
		if (promocode) {
			await storageSet('golkee_promocode', promocode);
			console.log('DEEP_LINKS: user (2): ', this.user);
			if (this.user) {
				this.apiService.processPromocode(promocode).subscribe(
					res => {
						console.log('DEEP_LINKS: processPromocode: ', res);
						if ('SUCCESS' === res) {
							console.log('DEEP_LINKS: removing promocode');
							storageRemove('golkee_promocode');
						}
					},
					error => {
						console.log('DEEP_LINKS: processPromocode: Error: ', error);
					}
				);
			}
		}

		if (slug) {
			this.router.navigateByUrl(slug);
		}
	}

	async presentLoader() {
		if (this.loadingElement) {
			await this.loadingElement.present();
		}
	}

	async dismissLoader() {
		if (this.loadingElement) {
			await this.loadingElement.dismiss();
		}
	}

	login() {
		this.authenticationService.logout();
		this.router.navigate(['auth/login']);
	}

	logout() {
		this.authenticationService.logout();
		this.router.navigate(['/auth/logout']);
	}

	async checkMyCascaisLogin(res) {

		this.authenticationService.myCascaisLogin(res)
		.pipe(first())
		.subscribe(data => {
			console.log('mycascaisLogin data: ' + JSON.stringify(data));
			// this.loggedIn = true;
			// if (this.isModal) {
			// 	// this.modalController.dismiss(true);
			// } else {
			// 	// this.router.navigate([this.returnUrl]);
			// }
			// this.processing = false;
			// this.dismissLoader();
		},
		error => {
			console.log('mycascaisLogin error: ' + JSON.stringify(error));
			this.dismissLoader();
			this.errorService.showError(error); // FIXME
			if (error && error.error && error.error['hydra:description'] && error.error['hydra:description'] === 'account_not_verified') {
				// this.presentResendVerificationPropmpt('mycascais', result.response.user);
			} else {
				// this.error = error;
				// this.processing = false;
				this.errorService.showError(error); // FIXME
				// if (this.isModal) {
				// 	this.dismiss();
				// }
			}
		});

	}

}
