import { Component, OnInit, Input } from '@angular/core';
import { DataService } from '@app/_services/data.service';
import { Cart } from '@app/_models/cart.model';
import { Place } from '@app/_models/place.model';
import { OrderItem } from '@app/_models/order.model';

@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

	@Input() cart: Cart;
	@Input() mode: string;
	@Input() currency: string;

	constructor(
		private dataService: DataService
	) {
		console.log('Hello CartComponent');
	}

	ngOnInit() {}

	increment(item, place: Place, marketplace: Place) {
		const newItem = Object.assign({}, item);
		newItem.quantity = 1;
		// 	product: item.product,
		// 	quantity: 1
		// } as OrderItem;
		this.dataService.addToCart(newItem, place, marketplace);
	}

	decrement(item, place: Place) {
		if (item.quantity <= 0) {
			return;
		}
		const newItem = Object.assign({}, item);
		newItem.quantity = 1;
		// const newItem = {
		// 	product: item.product,
		// 	quantity: 1
		// } as OrderItem;
		this.dataService.removeFromCart(newItem, place);
	}

}
