import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '@app/_services/authentication.service';
import { JwtInterceptor } from './jwt.interceptor';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, switchMap, filter, tap, take } from 'rxjs/operators';

// @see https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
	private refreshTokenInProgress = false;
	// Refresh Token Subject tracks the current token, or is null if no token is currently
	// available (e.g. refresh pending).
	private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
	constructor(public auth: AuthenticationService, public jwtInterceptor: JwtInterceptor) {
		console.log('Hello RefreshTokenInterceptor');
	}

	intercept(request: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
		// console.log('@@@TOKENS [refresh]: request: ', request);
		return next.handle(request)
		.pipe(
			catchError(error => {
				console.log('@@@TOKENS [refresh]: error: ', error);

				// We don't want to refresh token for some requests like login or refresh token itself
				// So we verify url and we throw an error if it's the case
				if (
					request.url.includes('token/refresh') ||
					request.url.includes('login_check')
				) {
					// We do another check to see if refresh token failed
					// In this case we want to logout user and to redirect it to login page

					if (request.url.includes('token/refresh')) {
						this.auth.logout(true);
					}

					// return Observable.throw(error);
					return throwError(error);
				}

				// If error status is different than 401 we want to skip refresh token
				// So we check that and throw the error if it's the case
				if (error.status !== 401) {
					// return Observable.throw(error);
					return throwError(error);
				}

				console.log('@@@TOKENS [refresh]: refreshTokenInProgress: ', this.refreshTokenInProgress);

				if (this.refreshTokenInProgress) {
					// If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
					// – which means the new token is ready and we can retry the request again
					return this.refreshTokenSubject
					.pipe(
						tap( result => console.log('@@@TOKENS [refresh]: refreshTokenSubject: ', result) ),
						filter(result => result !== null),
						take(1),
						switchMap(() => next.handle(this.addAuthenticationToken(request)))
					);
				} else {
					this.refreshTokenInProgress = true;

					// Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
					this.refreshTokenSubject.next(null);

					// Call auth.refreshAccessToken(this is an Observable that will be returned)
					return this.auth.refreshAccessToken()
					.pipe(
						tap( result => console.log('@@@TOKENS [refresh]: refreshAccessToken: ', result) ),
						switchMap((token: any) => {

							// When the call to refreshToken completes we reset the refreshTokenInProgress to false
							// for the next time the token needs to be refreshed
							this.refreshTokenInProgress = false;
							this.refreshTokenSubject.next(token);


							return next.handle(this.addAuthenticationToken(request));
						}),
						catchError((err: any) => {

							console.log('@@@TOKENS [refresh]: refreshAccessToken: err: (logout)', err);

							this.refreshTokenInProgress = false;

							this.auth.logout(true);
							// return Observable.throw(error);
							return throwError(error);

						})
					);
				}
			})
		);
	}

	// NOTE: also on jwt.interceptor
	addAuthenticationToken(request) {
		return this.jwtInterceptor.addAuthenticationToken(request);

		// // Get access token from Local Storage
		// const accessToken = this.auth.getAccessToken();
		//
		// // If access token is null this means that user is not logged in
		// // And we return the original request
		// if (!accessToken) {
		//     return request;
		// }
		//
		// // We clone the request, because the original request is immutable
		// return request.clone({
		//     setHeaders: {
		//         Authorization: this.auth.getAccessToken()
		//     }
		// });
	}
}
