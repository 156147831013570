import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Observable, zip } from 'rxjs';
import { NotificationService } from '@app/_services/notification.service';
import { DataService } from '@app/_services/data.service';
import { NotificationsModalPage } from '@app/notifications-modal/notifications-modal.page';
import { Notification, NotificationEnvelope } from '@app/_models/notification.model';
import { BillModalPage } from '@app/bill-modal/bill-modal.page';

@Component({
	selector: 'app-notification-icon',
	templateUrl: './notification-icon.component.html',
	styleUrls: ['./notification-icon.component.scss'],
})
export class NotificationIconComponent implements OnInit, OnDestroy {

	@Output() refresh = new EventEmitter<any>();

	notifications$: Observable<Notification[]>;
	unreadNotifications$: Observable<number>;
	totalUnreadNotifications$: Observable<number>;
	countNotifications = 0;
	unreadNotifications = 0;
	totalUnreadNotifications = 0;
	maxNotifications = 20;
	showPlusSign = false;
	sub: any;

	constructor(
		private router: Router,
		private modalController: ModalController,
		private notificationService: NotificationService,
		private dataService: DataService
	) {
		console.log('Hello NotificationIconComponent');
	}

	ngOnInit() {
		console.log('ngOnInit NotificationIconComponent');
		this.notifications$ = this.dataService.notifications$;
		this.unreadNotifications$ = this.dataService.unreadNotifications$;
		this.totalUnreadNotifications$ = this.dataService.totalUnreadNotifications$;

		this.sub = zip(this.notifications$, this.unreadNotifications$, this.totalUnreadNotifications$).subscribe(
			([notifications, unreadNotifications, totalUnreadNotifications]) => {
				this.unreadNotifications = unreadNotifications;
				this.totalUnreadNotifications = totalUnreadNotifications;
				this.countNotifications = 0;
				this.showPlusSign = this.unreadNotifications === this.maxNotifications && this.totalUnreadNotifications > this.unreadNotifications;
			}
		);

	}

	ngOnDestroy() {
		console.log('ngOnDestroy NotificationIconComponent');
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	async showNotifications() {

		// this.dataService.refreshPlace();
		this.refresh.emit(null);

		const modal = await this.modalController.create({
			component: NotificationsModalPage,
			cssClass: 'notifications-modal',
			id: 'NotificationsModal'
		});
		modal.onDidDismiss().then(
			response => {
				console.log('NotificationsModal dismiss: ', response);
				if (response.data != null) {
					if (response.data.notification) {
						const notification = response.data.notification;
						const route = this.notificationService.processNotificationEnvelope(notification.notification);
						console.log('notificationService response route: ', route);
						if (route) {
							if (Array.isArray(route)) {
								this.router.navigate(route);
							} else {
								// ex: modal:BillModal:1
								const parts = route.split(/:/);
								console.log('parts: ', parts);
								const type = parts[0];
								const identifier = parts[1];
								const args = parts[2];
								if ('modal' === type) {
									this.showModal(identifier, args);
								}
							}
						}
					}
				}
			}
		);
		await modal.present();
	}

	async showModal(identifier, args) {

		console.log('identifier: ', identifier);
		console.log('args: ', args);

		let component = null;
		let componentProps = null;

		switch (identifier) {
			case 'BillModal':
				const billId = parseInt(args, 10);
				const bill = this.dataService.getLoadedBillById(billId);
				if (bill) {
					component = BillModalPage;
					let action = '';
					switch (bill.status) {
						case 'open':
							break;
						case 'payment_requested':
							action = 'make_payment';
							break;
						case 'closed':
							break;
						default:
							action = 'close_window';
							break;
					}
					componentProps = { bill, action };
				}
				break;

			default:
				break;

		}

		if (component) {
			const modal = await this.modalController.create({
				component,
				cssClass: 'overlay-modal',
				id: identifier,
				componentProps
			});
			modal.onDidDismiss().then(() => {});
			await modal.present();
		}
	}
}
