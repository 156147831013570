import { Injectable } from '@angular/core';
import { Capacitor, Plugins } from '@capacitor/core';
const { Geolocation, NativeGeocoder } = Plugins;
// import { NativeGeocoder, NativeGeocoderResult, NativeGeocoderOptions } from '@ionic-native/native-geocoder/ngx';
import { HttpClient } from '@angular/common/http';

declare var google;

@Injectable({
	providedIn: 'root'
})
export class LocationService {

	// GOOGLE_API_KEY = 'AIzaSyBOTek6XW88Tb_7aU4PBx6GXx_sttbpajI'; // FIXME
	selectedLocation;

	constructor(
		// private nativeGeocoder: NativeGeocoder
		private http: HttpClient
	) {

	}

	public getSelectedLocation() {
		if (this.selectedLocation) {
			return Promise.resolve(this.selectedLocation);
		} else {
			return this.getCurrentLocation();
		}
	}

	public getCurrentLocation() {
		return Geolocation.getCurrentPosition();
	}

	public forwardGeocode(address, country): Promise<any> {


		if (Capacitor.isPluginAvailable('NativeGeocoder')) {
			// FIXME test this
			return NativeGeocoder.forwardGeocode(address);
		} else {

			return new Promise( (resolve, reject) => {

				console.log('Plugin not available: NativeGeocoder');
				// const url = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + encodeURI(address) + '&key=' + this.GOOGLE_API_KEY;
				// return this.http.get(url).toPromise();
				const geocoder = new google.maps.Geocoder();
				// return geocoder.geocode( { 'address': address} );
				// tslint:disable-next-line
				geocoder.geocode( { address: address, region: country}, function(results, status) {
					if (status === 'OK') {
						resolve(results);
					} else {
						reject(status);
					}
				});

			});
		}

	}

}
