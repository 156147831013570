import { Component, OnInit, OnDestroy, AfterViewInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { Product, ProductOption, ProductVariation } from '@app/_models/product.model';
import { Place } from '@app/_models/place.model';
import { Customer } from '@app/_models/customer.model';
import { OrderItem } from '@app/_models/order.model';
import { LoginPage } from '@app/auth/login/login.page';

@Component({
	selector: 'app-product-modal',
	templateUrl: './product-modal.page.html',
	styleUrls: ['./product-modal.page.scss'],
})
export class ProductModalPage implements OnInit, OnDestroy, AfterViewInit {

	// Data passed in by componentProps
	@Input() product: Product;
	@Input() marketplace: Place;
	@Input() place: Place;
	@Input() customer: Customer;
	@Input() checkoutEnabled: boolean;

	ready: boolean;
	valid: boolean;
	currency: string;
	customerSubscription: any;
	quantity = 1;
	price = 0;
	total = 0;
	grandTotal = 0;
	selectedOptions = [];
	selectedVariation: ProductVariation;

	constructor(
		private modalController: ModalController,
		private dataService: DataService
	) {
		console.log('Hello ProductModalPage');
	}

	ngOnInit() {
		console.log('ngOnInit ProductModalPage');
		this.ready = false;
		this.currency = this.place.currency;
		this.customerSubscription = this.dataService.customer$.subscribe(
			customer => {
				if (customer) {
					this.customer = customer;
				}
			}
		);
		this.dataService.getProductDetails(this.product).subscribe(
			product => {
				console.log('getProductDetails product: ', product);
				if (product) {
					this.product = product;
					// expand all first level options
					this.product.productOptions.forEach( option => {
						option.expanded = true;
					});

					this.price = this.product.price;
					this.total = this.price;
					this.recalculateTotal();

					this.valid = (this.product.productOptions.length > 0 || this.product.variations.length > 0) ? false : true;
					this.ready = true; // FIXME

				}
			}
		);
	}

	ngAfterViewInit() {
		console.log('ngAfterViewInit ProductModalPage');
	}

	ngOnDestroy() {
		console.log('ngOnDestroy ProductModalPage');
		this.customerSubscription.unsubscribe();
	}

	/* ====================================================================== */
	/*   C A R T
	/* ====================================================================== */

	inc() {
		this.quantity++;
		this.recalculateTotal();
	}

	dec() {
		this.quantity--;
		this.recalculateTotal();
	}

	recalculateTotal() {
		this.grandTotal = this.total * this.quantity;
	}

	addToCart() {
		if (this.customer && this.customer.id) {

			const item = {
				product: this.product,
				quantity: this.quantity,
				productOptions: this.selectedOptions,
				variation: this.selectedVariation
			} as OrderItem;
			this.dataService.addToCart(item, this.place, this.marketplace);

			// FIXME wait
			this.close();

		} else {

			this.showLoginModal();

		}

	}

	/* ====================================================================== */
	/*   V A R I A T I O N S
	/*   simple flat variations
	/* ====================================================================== */
	selectVariation(variation) {
		this.selectedVariation = null;
		this.product.variations.forEach( item => {
			if (item === variation) {
				item.selected = true;
				this.price = item.price;
				this.selectedVariation = item;
			} else {
				item.selected = false;
			}
		});
		if (this.product.productOptions.length > 0) {
			this.onProductOptionChanged(null);
		} else {
			this.total = this.price;
			this.recalculateTotal();
			this.valid = true;
		}
	}

	/* ====================================================================== */
	/*   O P T I O N S
	/*   complex hierarchical options
	/* ====================================================================== */

	onProductOptionChanged(option: ProductOption) {
		console.log('onProductOptionChanged: ', option);
		const obj = this.processTree(option, this.product.productOptions);
		this.total = this.price + obj.total;
		this.selectedOptions = obj.selected;
		this.valid = this.validate(this.product.productOptions);
		this.recalculateTotal();
	}


	private validateNode(node, isRequired, minQty) {

		console.log('### validating ', node.name);
		// console.log('### validating children ', node.children.length);

		let count = 0;

		// validate the deepest node
		if (node.children && node.children.length > 0) {

			for (const child of node.children) {

				// console.log('### validating child ', child.name);

				if ((child.selected || child.kind === 'group') && (child.children && child.children.length > 0)) {

					// override parent
					// const childMinQty = (child.kind === 'group' && child.minQty > minQty) ? child.minQty : minQty;
					const childMinQty = (child.kind === 'group') ? child.minQty : minQty;
					const childIsRequired = (child.kind === 'group') ? child.isRequired : isRequired;

					const valid = this.validateNode(child, childIsRequired, childMinQty);

					// first to fail invalidates all
					if (!valid) {
						return false;
					}

				}

				if (child.selected || child.kind === 'group') {
					// console.log('### val selected ', child.name);
					count++;
				}

			}

			if (isRequired && count === 0) {
				console.log('### validation failed [isRequired] at ', node.name);
				return false;
			}

			if (minQty && count < minQty) {
				// console.log('### validation failed [minQty] at ', node.name);
				console.log('### validation failed [count < minQty] ', count, minQty);
				return false;
			}

		}

		return true;

	}

	private validate(nodes) {
		for (const node of nodes) {
			if (node.kind === 'group') {
				const isRequired = node.isRequired;
				const minQty = node.minQty;
				const valid = this.validateNode(node, isRequired, minQty);
				if (!valid) {
					return false;
				}
			}
		}
		// console.log('### validation passed at ' + nodes[0].parent);
		return true;
	}

	/**
	 * Called when the user selects/deselects a given option
	 * 		- updates total
	 * 		- deselects siblings on single choice options
	 * 		- enforces max selected items on multiple choice options
	 *  Returns:
	 *      - count: 	count selected items on each node
	 *      - total:	calculated total for each node
	 *      - selected:	list of selected nodes
	 */
	private processTree(targetOption: ProductOption, nodes): any {
		let count = 0;
		let total = 0;
		let selected = [];

		// add the target option to count if selected
		if (targetOption && targetOption.selected) {
			count++;
		}

		// iterate over each product option, recursively
		nodes.forEach( option => {

			console.log('#processTree: option: ', option.name);

			// add the target option to count if selected
			// if (option === targetOption && targetOption.selected) {
			// 	count++;
			// }

			// if option is NOT the target option
			if (option !== targetOption) {

				// if option is a sibling of the target option
				if (targetOption && option.parent === targetOption.parent) {

					// if selected update the number of selected options on the current node
					if (option.selected) {
						count++;
					}

					// if the target option is selected, get its parent
					// to the determine the node properties
					if (targetOption.selected && option.parent) {

						// need to lookup the parent by iri
						const parent = this.lookupParentByIri(option.parent, this.product.productOptions);

						if (parent) {
							// verify and enforce limits
							const maxQty = parent.maxQty;
							if (maxQty) {
								// if single choice, deselect all siblings
								if (maxQty === 1) {
									if (targetOption.selected && option.selected) {
										option.selected = false;
										option.expanded = false;
										this.deselectChildren(option.children);
									}
								// if multiple choice, do not allow selecting if the max limit has been reached
								} else {

									console.log('#processTree: count: ', count);
									console.log('#processTree: maxQty: ', maxQty);

									if (count > maxQty) {
										if (targetOption.selected) {
											targetOption.selected = false;
											count--;
										}
										// FIXME add error to parent
									}
								}
							}
						}
					}

				// if the option is not a sibling of the target option and has children
				// process this branch recursively
				} else {
					if (option.children && option.children.length > 0) {
						const obj = this.processTree(targetOption, option.children);
						count += obj.count; // ???
						total += obj.total;
						selected = selected.concat(obj.selected);
						// valid = valid && obj.valid;
						// console.log('processTree selected (2): ', selected);
					}
				}
			}

			// if after all verifications the option is selected
			// update the total price and the selection map
			if (option.selected) {
				// console.log('processTree: add: ', option.name, option.price);
				total += option.price;
				selected.push(option);
				// console.log('processTree selected (1): ', selected);
			}

		});

		return {count, total, selected}; // , valid};

	}

	private deselectChildren(nodes) {
		nodes.forEach( option => {
			option.selected = false;
			if (option.children) {
				this.deselectChildren(option.children);
			}
		});
	}

	private deselectSiblings(selectedOption: ProductOption, nodes) {
		nodes.forEach( option => {
			if (option !== selectedOption) {
				if (option.parent === selectedOption.parent) {
					option.selected = false;
					option.expanded = false;
				} else {
					if (option.children) {
						this.deselectSiblings(selectedOption, option.children);
					}
				}
			}
		});
	}

	private lookupParentByIri(parentIri, nodes) {
		// console.log('lookupParentByIri: ', parentIri);
		for (const node of nodes) {
			// console.log('lookupParentByIri: node: ', node);
			if (node['@id'] === parentIri) {
				return node;
			} else {
				if (node.children) {
					const parent = this.lookupParentByIri(parentIri, node.children);
					if (parent) {
						return parent;
					}
				}
			}
		}
		// console.log('lookupParentByIri: not found');
		return null;
	}

	/* ====================================================================== */
	/*   M O D A L S
	/* ====================================================================== */

	async showLoginModal() {
		const modal = await this.modalController.create({
			component: LoginPage,
			cssClass: 'login-modal',
			componentProps: {
				isModal: true
			}
		});
		modal.onWillDismiss().then(() => {});
		modal.present();
	}

	close() {
		this.modalController.dismiss();
	}

}
