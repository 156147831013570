import { Component, OnInit, Input } from '@angular/core';
import { GroupReservation } from '@app/_models/reservation.model';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { TranslateService } from '@ngx-translate/core';
import { Place } from '@app/_models/place.model';
import { environment } from '../../environments/environment';

@Component({
	selector: 'app-reservation-modal',
	templateUrl: './reservation-modal.page.html',
	styleUrls: ['./reservation-modal.page.scss'],
})
export class ReservationModalPage implements OnInit {

	// Data passed in by componentProps
	@Input() reservation: GroupReservation;
	@Input() action: string;
	@Input() mode: string;

	cancelTitle: string;
	cancelMessage: string;
	dismissButton: string;
	confirmButton: string;

	submitted  = false;
	processing = false;
	reserved   = false;
	canceled   = false;
	error      = null;

	place: Place;
	identifier: string;
	uuid: string;

	constructor(
		private modalController: ModalController,
		private alertController: AlertController,
		private dataService: DataService,
		private translate: TranslateService
	) {
		console.log('Hello ReservationModalPage');
		this.translate.get('reservations.label.cancel_warning').subscribe(value => { this.cancelTitle = value; });
		this.translate.get('reservations.text.confirm_cancelation').subscribe(value => { this.cancelMessage = value; });
		this.translate.get('reservations.buttons.dismiss').subscribe(value => { this.dismissButton = value; });
		this.translate.get('reservations.buttons.confirm_cancelation').subscribe(value => { this.confirmButton = value; });

	}

	ngOnInit() {
		console.log('ngOnInit ReservationModalPage');
		this.place = this.reservation.place as Place;
		// this.identifier = this.reservation.qrcode ? this.reservation.qrcode.uuid : null;
		if (this.reservation && this.reservation.qrcode) {
			this.uuid = this.reservation.qrcode.uuid;
			this.identifier = environment.golkee.qrCodeServer + '/' + this.uuid;
		}
	}

	makeReservation() {
		this.processing = true;
		this.submitted = true;
		this.dataService.makeReservation(this.reservation).subscribe(
			reserved => {
				console.log('reserved: ', reserved);
				this.reserved = reserved;
				this.processing = false;
			},
			error => {
				console.log('error: ', error);
				if (error.error && error.error['hydra:description']) {
					this.error = error.error['hydra:description'];
				} else if (error.message) {
					this.error = error.message;
				} else {
					this.error = 'unknown_error';
				}
				this.processing = false;
			}
		);
		// this.close();
	}

	cancelReservation() {
		this.processing = true;
		this.submitted = true;
		this.dataService.cancelReservation(this.reservation).subscribe(
			canceled => {
				console.log('canceled: ', canceled);
				this.canceled = canceled;
				this.processing = false;
			},
			error => {
				console.log('error: ', error);
				if (error.error && error.error['hydra:description']) {
					this.error = error.error['hydra:description'];
				} else if (error.message) {
					this.error = error.message;
				} else {
					this.error = 'unknown_error';
				}
				this.processing = false;
			}
		);

	}

	async cancelReservationConfirm() {
		const alert = await this.alertController.create({
			header: this.cancelTitle,
			message: this.cancelMessage,
			buttons: [
				{
					text: this.dismissButton,
					role: 'cancel',
					cssClass: 'secondary',
					handler: (blah) => {
						console.log('Confirm Cancel: blah');
						this.close();
					}
				}, {
					text: this.confirmButton,
					handler: () => {
						console.log('Confirm Okay');
						this.cancelReservation();
						// this.close();
					}
				}
			]
		});
		await alert.present();
	}

	close() {
		this.modalController.dismiss();
	}

}
