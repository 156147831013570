import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared.module';

import { IonicModule } from '@ionic/angular';

import { NewReservationModalPageRoutingModule } from './new-reservation-modal-routing.module';

import { NewReservationModalPage } from './new-reservation-modal.page';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		FormsModule,
		IonicModule,
		NewReservationModalPageRoutingModule
	],
	declarations: [NewReservationModalPage]
})
export class NewReservationModalPageModule {}
