import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared.module';

import { IonicModule } from '@ionic/angular';

import { SlotModalPageRoutingModule } from './slot-modal-routing.module';

import { SlotModalPage } from './slot-modal.page';

@NgModule({
	imports: [
		SharedModule,
		CommonModule,
		FormsModule,
		IonicModule,
		SlotModalPageRoutingModule
	],
	declarations: [SlotModalPage]
})
export class SlotModalPageModule {}
