import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared.module';
import { QRCodeModule } from 'angularx-qrcode';

import { IonicModule } from '@ionic/angular';

import { ReservationModalPageRoutingModule } from './reservation-modal-routing.module';

import { ReservationModalPage } from './reservation-modal.page';

@NgModule({
	imports: [
		SharedModule,
		QRCodeModule,
		CommonModule,
		FormsModule,
		IonicModule,
		ReservationModalPageRoutingModule
	],
	declarations: [ReservationModalPage]
})
export class ReservationModalPageModule {}
