import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ApiService } from '../_services/api.service';

@Injectable({
	providedIn: 'root'
})
/**
 * Email Validator
 * Checks if email already exists on server
 * @see https://www.joshmorony.com/username-availability-with-an-asynchronous-validator-in-angular/
 * @see https://codinglatte.com/posts/angular/how-to-add-async-validators-to-an-angular-reactive-form/
 */
export class EmailValidator {

	// debouncer: any;

	constructor(
		public apiService: ApiService
	) {
		console.log('Hello EmailValidator');
	}

	emailInUse(control: FormControl): any {

		// clearTimeout(this.debouncer);

		return new Promise(resolve => {

			// this.debouncer = setTimeout(() => {

				if (control.value.indexOf('@') === -1) {
					resolve(null);
					return;
				}

				this.apiService.validateEmail(control.value).subscribe((res) => {
					console.log('emailInUse response: ', res);
					if (res === 'OK') {
						resolve(null);
					} else {
						// should never happen
						resolve({unknown_error: true});
					}
				}, (err) => {
					console.log('emailInUse error: ', err);
					const key = err.error ? err.error : 'unknown_error';
					resolve({[key]: true});
				});

			// }, 1000);

		});

	}
}
