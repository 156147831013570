import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {

	errorMessages = {};

	constructor(
		public alertController: AlertController,
		private translate: TranslateService
	) {
		console.log('Hello ErrorService ');
		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			// do something
			this.translate.get('errors').subscribe( errors => {
				this.errorMessages = errors;
				// console.log('error messages: ', errors);
			});
		});
	}

	public showError(error) {
		console.log('showError (object): ', error);
		let message;

		if (error) {
			// skip unauthorized errors
			if (error.status && error.status === 401) {
				return;
			}
			if (error.error && error.error['hydra:description']) {
				message = error.error['hydra:description'];
			} else if (error.message) {
				message = error.message;
			} else {
				message = error;
			}
		} else {
			message = 'unknown_error';
		}

		// tslint:disable-next-line:no-string-literal
		const title = error && error.title ? error.title : this.errorMessages['generic_title'];
		const errorKey = message;
		message = this.errorMessages[errorKey] ? this.errorMessages[errorKey] : message;
		const buttons = ['OK'];
		this.presentAlert(title, '', message, buttons);

	}

	async presentAlert(header, subHeader, message, buttons = ['OK']) {
		const alert = await this.alertController.create({
			header,
			subHeader,
			message,
			buttons
		});

		await alert.present();
	}

}
