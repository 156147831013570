import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { Notification, NotificationEnvelope } from '@app/_models/notification.model';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-notifications-modal',
	templateUrl: './notifications-modal.page.html',
	styleUrls: ['./notifications-modal.page.scss'],
})
export class NotificationsModalPage implements OnInit {

	notifications$: Observable<Notification[]>;

	readDelay = 5000;
	index = 0;
	notifications = [];

	constructor(
		private modalController: ModalController,
		private dataService: DataService
	) {
		console.log('Hello NotificationsModalPage');
	}

	ngOnInit() {
		console.log('ngOnInit NotificationsModalPage');
		this.notifications$ = this.dataService.notifications$;
		this.refresh();
	}

	markAllNotificationsAsRead() {
		this.dataService.markAllNotificationsAsRead();
	}

	markNotificationAsRead(notification) {
		if (!notification.readAt) {
			this.dataService.markNotificationAsRead(notification);
		}
	}

	onNotificationClick(notification) {
		this.markNotificationAsRead(notification);
		this.dismiss(notification);
	}

	refresh() {
		this.dataService.refreshNotifications();
	}

	dismiss(notification) {
		this.modalController.dismiss({notification}, null, 'NotificationsModal');
	}

	close() {
		this.dismiss(null);
	}

}
