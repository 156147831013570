import { Component, OnInit, Input } from '@angular/core';
import { GroupReservation } from '@app/_models/reservation.model';
import { Place } from '@app/_models/place.model';
import { Customer } from '@app/_models/customer.model';
import { ModalController, AlertController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';

@Component({
	selector: 'app-new-reservation-modal',
	templateUrl: './new-reservation-modal.page.html',
	styleUrls: ['./new-reservation-modal.page.scss'],
})
export class NewReservationModalPage implements OnInit {

	// Data passed in by componentProps
	@Input() place: Place;
	@Input() customer: Customer;

	reservation: GroupReservation;
	action = 'make_reservation';

	// FIXME translate
	dayShortNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
	monthShortNames = ['Jan', 'Feb', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
	minDateAndTime: string;
	maxDateAndTime: string;
	selectedDateAndTime: string;

	submitted  = false;
	processing = false;
	reserved   = false;
	canceled   = false;
	error      = null;
	valid 	   = false;

	constructor(
		private modalController: ModalController,
		private alertController: AlertController,
		private dataService: DataService
	) {
		console.log('Hello NewReservationModalPage');
	}

	ngOnInit() {
		console.log('ngOnInit NewReservationModalPage');

		// const timeInAdvance = 60; // in minutes
		const now = new Date();
		let hours = now.getHours();
		const minutes = now.getMinutes();
		if (minutes > 30) {
			hours += 2;
		} else {
			hours += 1;
		}
		// FIXME adjust to place schedule
		// min date and time within n hours from now
		const maxReservationPeriod = 30; // in days
		const minDateAndTime = new Date(now.getTime());
		minDateAndTime.setHours(hours, 0, 0, 0);

		const maxDateAndTime = new Date();
		maxDateAndTime.setDate(minDateAndTime.getDate() + maxReservationPeriod);
		const selectedDateAndTime = minDateAndTime;

		console.log('NewReservationModalPage: minDateAndTime: ', minDateAndTime);
		console.log('NewReservationModalPage: maxDateAndTime: ', maxDateAndTime);
		console.log('NewReservationModalPage: selectedDateAndTime: ', selectedDateAndTime);


		this.minDateAndTime = minDateAndTime.toISOString();
		this.maxDateAndTime = maxDateAndTime.toISOString();
		this.selectedDateAndTime = selectedDateAndTime.toISOString();

		const numPax = 2;
		const comments = '';
		this.reservation = this.dataService.newReservation(this.customer, this.place, this.selectedDateAndTime, numPax, comments);
	}

	inc() {
		this.reservation.numPax++;
	}

	dec() {
		if (this.reservation.numPax > 0) {
			this.reservation.numPax--;
		}
	}

	makeReservation() {
		this.processing = true;
		this.submitted = true;
		this.dataService.makeReservation(this.reservation).subscribe(
			reserved => {
				console.log('reserved: ', reserved);
				this.reserved = reserved;
				this.processing = false;
			},
			error => {
				console.log('error: ', error);
				if (error.error && error.error['hydra:description']) {
					this.error = error.error['hydra:description'];
				} else if (error.message) {
					this.error = error.message;
				} else {
					this.error = 'unknown_error';
				}
				this.processing = false;
			}
		);
		// this.close();
	}

	// @see https://stackoverflow.com/a/28149561/2393822
	private toLocalISOString(d) {
		const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
		// The slice(0, -1) gets rid of the trailing Z which represents Zulu timezone and can be replaced by your own.
		const localISOTime = (new Date(d - tzoffset)).toISOString().slice(0, -1);
		return localISOTime;
	}

	validateSelection() {
		console.log('DatetimeSelectionPage: validateTimeSelection: ', event);
		const start = new Date(this.minDateAndTime);
		const selection = new Date(this.selectedDateAndTime);
		this.valid = (selection > start);
	}

	close() {
		this.modalController.dismiss();
	}

}
