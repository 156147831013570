import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProductOption } from '@app/_models/product.model';

@Component({
	selector: 'app-product-option',
	templateUrl: './product-option.component.html',
	styleUrls: ['./product-option.component.scss'],
})
export class ProductOptionComponent implements OnInit {

	@Input() option: ProductOption;
	@Input() parent: ProductOption;
	@Input() currency: string;
	@Output() changed = new EventEmitter<ProductOption>();

	constructor() {
		console.log('Hello ProductOptionComponent');
	}

	ngOnInit() {}

	toggle(option) {
		option.expanded = !option.expanded;
		option.selected = !option.selected;

		console.log(option.name + ' expanded: ', option.expanded);

		if (option.expanded) {

			// option.siblings.forEach(sibling => {
			// 	sibling.expanded = false;
			// 	sibling.selected = false;
			// });

			const firstChild = option.children.length > 0 ? option.children[0] : null;
			console.log(option.name + ' firstChild: ', firstChild);
			if (firstChild) {
				firstChild.expanded = true;
			}

		}

		this.changed.emit(option);

	}

	onProductOptionChanged(option) {
		console.log('(comp) onProductOptionChanged: ', option);
		this.changed.emit(option);
	}

}
