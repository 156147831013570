import { Pipe, PipeTransform } from '@angular/core';
import { HumanizeDurationLanguage, HumanizeDuration } from 'humanize-duration-ts';

@Pipe({
	name: 'humanizeDuration'
})
/**
 * FIXME TODO translations and localizations
 */
export class HumanizeDurationPipe implements PipeTransform {


	langService: HumanizeDurationLanguage;
	humanizer: HumanizeDuration;

	constructor() {
		this.langService = new HumanizeDurationLanguage();
		this.humanizer = new HumanizeDuration(this.langService);
	}

	transform(value: string): string {

		const d1 = new Date(value);
		const t1 = d1.getTime();
		const d2 = new Date();
		const t2 = d2.getTime();

		// date in the future
		if (t1 > t2) {
			return 'faltam ' + this.humanizer.humanize(t1 - t2, { round: true, largest: 1, language: 'pt' });
		// date in the past
		} else if (t1 < t2) {
			return 'há ' + this.humanizer.humanize(t1 - t2, { round: true, largest: 1, language: 'pt' });
		// now
		} else {
			return 'agora';
		}
	}

}
