/**
 * Shared Module
 * @see https://medium.com/@lopesgon/translate-angular-4-with-ngx-translate-and-multiple-modules-7d9f0252f139
 * @see https://angular.io/guide/sharing-ngmodules
 */

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ShellModule } from '@app/_shell/shell.module';
import { PipesModule } from '@app/_pipes/pipes.module';
import { ComponentsModule } from '@app/_components/components.module';

@NgModule({
	imports: [
		TranslateModule
	],
	exports: [
		TranslateModule,
		ShellModule,
		PipesModule,
		ComponentsModule
	]
})

export class SharedModule { }
