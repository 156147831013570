import { Injectable } from '@angular/core';
import { ApiService } from '@app/_services/api.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ConfigurationService {

	version = '1.1.4';
	configuration: any;

	constructor(
		private apiService: ApiService
	) {
		console.log('Hello ConfigurationService');
	}

	getVersion() {
		const version = this.version;
		const tag = environment.tag;
		if ('prod' === tag) {
			return version;
		}
		if ('stage' === tag) {
			return (version + '-DEMO');
		}
		return version + '-DEV';
	}

	// TODO
	loadConfiguration(): Observable<boolean> {
		this.configuration = {};
		return new Observable((observer) => {
			this.apiService.getConfiguration().subscribe(
				res => {
					console.log('ConfigurationService: api response: ', res);
					if (res && res['hydra:member']) { //  && res['hydra:member'].length > 0) {
						const entries = res['hydra:member'];
						entries.forEach( entry => {
							if (entry) {
								this.configuration[entry.attrKey] = entry.attrValue;
							}
						});
						console.log('ConfigurationService: configuration: ', this.configuration);
						if (this.validateConfiguration()) {
							observer.next(true);
							observer.complete();
						} else {
							observer.error('invalid_configuration');
						}
					} else {
						observer.error('error_loading_configuration');
					}
				},
				error => {
					console.log('ConfigurationService error: ', error);
					observer.error(this.parseError(error));
					observer.complete();
				}
			);
		});
	}

	validateConfiguration() {
		const neededKeys = [];
		for (const key of neededKeys) {
			if (!this.configuration[key]) {
				return false;
			}
		}
		return true;
	}

	getConfigurationByKey(key) {
		return this.configuration[key] ? this.configuration[key] : null;
	}

	parseError(error) {
		let message = '';

		if (error) {
			if (error.error && error.error['hydra:description']) {
				message = error.error['hydra:description'];
			} else if (error.message) {
				message = error.message;
			} else {
				message = error;
			}
		} else {
			message = 'unknown_error';
		}
		return message;
	}
}
