import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DataService } from '@app/_services/data.service';
import { Bill } from '@app/_models/bill.model';
import { PaymentModalPage } from '@app/payment-modal/payment-modal.page';

@Component({
  selector: 'app-bill-modal',
  templateUrl: './bill-modal.page.html',
  styleUrls: ['./bill-modal.page.scss'],
})
export class BillModalPage implements OnInit {

	// Data passed in by componentProps
	@Input() bill: Bill;
	@Input() action: string;
	@Input() mode: string;

	constructor(
		private modalController: ModalController,
		private dataService: DataService
	) {
		console.log('Hello BillModalPage');
	}

	ngOnInit() {
		console.log('ngOnInit BillModalPage');
	}

	ionViewWillEnter() {
		console.log('ionViewWillEnter BillModalPage');
	}

	ionViewWillLeave() {
		console.log('ionViewWillLeave BillModalPage');
	}

	// dismiss(result) {
	// 	this.modalController.dismiss({result}, null, 'BillModal');
	// }

	close(result) {
		this.modalController.dismiss(result, null, 'BillModal');
	}


	async showPaymentModal() {
		const modal = await this.modalController.create({
			component: PaymentModalPage,
			cssClass: 'overlay-modal payment-modal',
			componentProps: {
				bill: this.bill
			},
			id: 'PaymentModal'
		});
		modal.onWillDismiss().then(
			(result) => {
				if ('success' === result.data) {
					this.close('success');
				}
			}
		);
		modal.present();
	}

}
