import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

/**
 * @see https://levelup.gitconnected.com/a-guide-to-managing-loading-state-for-angular-app-api-requests-83b7429d0e3a
 */
export class LoaderService {
	public loading$: Subject<boolean> = new Subject();

	constructor() { }

	startLoading() {
		// console.log('### loader: start');
		this.loading$.next(true);
	}

	stopLoading() {
		// console.log('### loader: end');
		this.loading$.next(false);
	}
}
